import { SvgIconProps } from "@mui/material/SvgIcon";
import { ReactElement } from "react";

export interface Timestamps {
  createdAt: string;
  updatedAt: string;
}

export interface BankAccountType {
  bankName: string;
  accountNumber: string;
  accountOwner: string;
}

export interface User extends Timestamps {
  _id: string;
  name: string;
  email: string;
  phone: string;
  isAdmin?: boolean;
  isActive: boolean;
  address?: string;
  companyName?: string;
  position?: string;
  password: string;
  jwt: string;
  bankAccount: BankAccountType;
}

export type Profile = Omit<User, "password" | "jwt">;

export type UpdateBankAccountFormType = BankAccountType & { otp: string };

export interface Transaction extends Timestamps {
  _id: string;
  name: string;
  transactionState: TransactionStateEnum;
  creator: string;
  contractState: ContractStateEnum;
  adminA: Profile;
  adminB: Profile;
  membersA: Array<Profile>;
  membersB: Array<Profile>;
  contract: string;
  conversations: Array<Message>;
  amountOfMoney: number | string;
  depositRate: number | string;
  transferCode: string;
  transactionFees: number | string;
}

export interface ContractState {
  isConfirmed: boolean;
  isWaitA: boolean;
  isWaitB: boolean;
  isDrafting: boolean;
}

export interface TransactionAdmin {
  isAdminA: boolean;
  isAdminB: boolean;
  isTransactionAdmin: boolean;
}

export enum ContractStateEnum {
  Drafting = "drafting",
  WaitA = "waita",
  WaitB = "waitb",
  Confirmed = "confirmed",
}

export enum TransactionStateVieEnum {
  Created = "Đã tạo",
  Deposited = "Đã đặt cọc",
  Sent = "Đã gửi hàng",
  Received = "Đã nhận hàng",
  Accomplished = "Đã hoàn thành",
}

export enum TransactionStateEnum {
  Created = "created",
  Deposited = "deposited",
  Sent = "sent",
  Received = "received",
  Accomplished = "accomplished",
}

export const getTSVieToEng = (value: string) => {
  switch (value) {
    case TransactionStateVieEnum.Deposited: {
      return TransactionStateEnum.Deposited;
    }
    case TransactionStateVieEnum.Sent: {
      return TransactionStateEnum.Sent;
    }
    case TransactionStateVieEnum.Received: {
      return TransactionStateEnum.Received;
    }
    case TransactionStateVieEnum.Accomplished: {
      return TransactionStateEnum.Accomplished;
    }
    default:
      return TransactionStateEnum.Created;
  }
};

export const getTSEngToVie = (value: string) => {
  switch (value) {
    case TransactionStateEnum.Deposited: {
      return TransactionStateVieEnum.Deposited;
    }
    case TransactionStateEnum.Sent: {
      return TransactionStateVieEnum.Sent;
    }
    case TransactionStateEnum.Received: {
      return TransactionStateVieEnum.Received;
    }
    case TransactionStateEnum.Accomplished: {
      return TransactionStateVieEnum.Accomplished;
    }
    default:
      return TransactionStateVieEnum.Created;
  }
};

export enum MessageEnum {
  MessageA = "messagea",
  MessageB = "messageb",
}

export enum GroupEnum {
  A = "A",
  B = "B",
}

export interface Message {
  _id: string;
  senderID: string;
  senderName: string;
  message: string;
  messageType: MessageEnum;
  createdAt: string;
}

export interface MenuItemAction {
  actionItem?: React.ReactNode;
  actionName: string | ReactElement<SvgIconProps>;
  onClickMenuItem: (d?: TransactionStateEnum) => void;
}

export interface FeeRate {
  _id: string;
  amountFrom: number | string;
  amountTo: number | string;
  feeRate: number | string;
}

export interface Step {
  label: string | React.ReactNode;
  contentComponent: React.ReactNode;
  actionComponent?: React.ReactNode; //dùng khi ở step cuối (bắt đầu là 1 button)
  enabledNext: boolean; //điều kiện sáng nút action
  nextAction?: (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | Promise<void>; //dùng khi chưa đến bước cuối mà cần gọi api
}
