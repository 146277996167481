import { toast } from "react-toastify";
import { TransactionStateEnum } from "../types/type";

export const convertNumberToVND = (value: number | string) => {
  const formatter = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  });

  return formatter.format(Number(value));
};

export const convertNumberWithDot = (value: number | string) => {
  return Number(value).toLocaleString("de-DE");
};

export const keepNumberOfString = (value: number | string) => {
  const numberString = String(value).replace(/\D/g, ""); // loại bỏ tất cả các ký tự không phải số
  const number = parseInt(numberString);
  return number;
};

export const isValidPhoneNumber = (phoneNumber: string) => {
  // Biểu thức chính quy để kiểm tra số điện thoại Việt Nam
  const regex = /^(?:\+?84|0)(?:\d){9,10}$/;

  return regex.test(phoneNumber);
};

export const isValidEmail = (email: string) => {
  // Biểu thức chính quy để kiểm tra email hợp lệ
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return regex.test(email);
};

export const isValidPassword = (password: string) => {
  // Biểu thức chính quy để kiểm tra password hợp lệ
  const regex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;

  return regex.test(password);
};

export const getTransactionStateColor = (transactionState: string) => {
  switch (transactionState) {
    case TransactionStateEnum.Deposited: {
      return "#f1c40f";
    }
    case TransactionStateEnum.Sent: {
      return "#e25822";
    }
    case TransactionStateEnum.Received: {
      return "#9b59b6";
    }
    case TransactionStateEnum.Accomplished: {
      return "#27ae60";
    }
    default:
      return "#3498db";
  }
};

export const alertStatus = (message: string) => {
  if (message === "You are not creator of this transaction") {
    toast.error("Bạn không phải là người tạo giao dịch này");
    return;
  }
  if (
    message === "Can't delete cause the contract has already been confirmed"
  ) {
    toast.error("Không thể xoá vì hợp đồng đã được cả 2 bên ký");
    return;
  }
  if (message === "You are not admin of B group") {
    toast.error("Bạn không phải Admin bên Bán");
    return;
  }
  if (message === "You are not admin of A group") {
    toast.error("Bạn không phải Admin bên Mua");
    return;
  }
  if (message === "This member already added") {
    toast.error("Thành viên này đã được thêm vào giao dịch này");
    return;
  }
  //update contract
  if (
    message ===
    "You can't update contract content when transaction is not drafting"
  ) {
    toast.error("Lưu thất bại. Bên của bạn đã bấm xác nhận");
    return;
  }
  if (message === "You are not member of this transaction") {
    toast.error("Lưu thất bại. Bạn không phải thành viên của giao dịch này");
    return;
  }
  if (message === "The contract has not been confirmed") {
    toast.error(
      "Không thể cập nhật trạng thái giao dịch vì hợp đồng chưa được 2 bên ký"
    );
    return;
  }

  if (message === "User is already registered") {
    toast.error("Không thể gửi mã OTP. Email này đã được sử dụng");
  }
  if (message === "User already exists") {
    toast.error("Email hoặc số điện thoại đã được sử dụng");
    return;
  }
  if (message === "Invalid OTP") {
    toast.error("Mã OTP không chính xác");
    return;
  }
  if (message === "The OTP code has expired, please resend another") {
    toast.error("Mã OTP đã hết hạn. Vui lòng gửi lại mã khác");
    return;
  }
  if (message === "OTP code has been sent, please try again after 5 minutes") {
    toast.error("Mã OTP đã được gửi. Vui lòng thử lại sau 5 phút");
    return;
  }
  if (message === "This account does not exist") {
    toast.error("Tài khoản này chưa được sử dụng");
    return;
  }
  if (message === "Invalid password") {
    toast.error(
      "Mật khẩu phải lớn hơn 8 ký tự, có chữ hoa và chữ thường, bao gồm cả chữ và số"
    );
    return;
  }
};
