import styled from "styled-components";
import FormWrapper from "../components/FormWrapper/FormWrapper";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";
import UserService from "../services/UserService";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import {
  alertStatus,
  isValidEmail,
  isValidPassword,
  isValidPhoneNumber,
} from "../untils/untils";
import { Step, User } from "../types/type";
import { INITIAL_PROFILE } from "../untils/constants";
import SStepper from "../components/Stepper/SStepper";
import EmailInput from "../components/EmailInput";
import RequiredInput from "../components/Register/RequiredInput";
import OptionalInput from "../components/Register/OptionalInput";
import RegistrationReview from "../components/Register/RegistrationReview";

export const FormContainer = styled.div`
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export interface RegisterStepsProps {
  newUser: Omit<User, "jwt"> & {
    otp: number | string;
  };
  setNewUser: React.Dispatch<
    React.SetStateAction<
      Omit<User, "jwt"> & {
        otp: number | string;
      }
    >
  >;
}

const RegisterPage = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [newUser, setNewUser] = useState<
    Omit<User, "jwt"> & { otp: number | string }
  >({
    ...INITIAL_PROFILE,
    password: "",
    otp: "",
  });

  const navigate = useNavigate();

  const sendOTPMutation = useMutation({
    mutationFn: (email: string) => {
      return UserService.sendOTP(email, "register");
    },
    onSuccess: () => {
      toast.success(
        `Mã OTP đã được gửi đến ${newUser.email}. Vui lòng kiểm tra Hộp thư đến để lấy mã OTP và nhập vào ô "Mã OTP"`
      );
      handleNext();
    },
    onError: (error: any) => {
      const errorMessage = error.response.data.message;
      alertStatus(errorMessage);
      if (errorMessage === "User is already registered") {
        setActiveStep(0);
        return;
      }
      handleNext();
    },
  });

  const registerMutation = useMutation({
    mutationFn: () => {
      const { isActive, isAdmin, createdAt, updatedAt, _id, ...newUserRest } =
        newUser;
      return UserService.register(newUserRest);
    },
    onSuccess: () => {
      navigate("/login", { state: { status: "success-register" } });
    },
    onError: (error: any) => {
      const errorMessage = error.response.data.message;
      alertStatus(errorMessage);
    },
  });

  if (
    Object.keys(JSON.parse(localStorage.getItem("currentUser") || "{}"))
      .length > 0
  ) {
    return <Navigate to="/" />;
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSendOTP = () => {
    sendOTPMutation.mutate(newUser.email);
  };

  const handleCheckOTP = () => {
    UserService.checkOTP({ email: newUser.email, otp: newUser.otp })
      .then((res) => {
        toast.success("Mã OTP chính xác");
        handleNext();
      })
      .catch((error) => {
        alertStatus(error.response.data.message);
        setActiveStep(1);
      });
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    registerMutation.mutate();
  };

  const REGISTER_STEPS: Step[] = [
    {
      label: (
        <>
          Nhập Email <br /> (Lấy Mã OTP)
        </>
      ),
      contentComponent: (
        <EmailInput
          data={newUser}
          setFunction={(value) => {
            setNewUser({ ...newUser, email: value });
          }}
        />
      ),
      enabledNext: !!newUser.email && isValidEmail(newUser.email),
      nextAction: handleSendOTP,
    },
    {
      label: (
        <>
          Nhập Thông Tin <br /> (Bắt Buộc)
        </>
      ),
      contentComponent: (
        <RequiredInput newUser={newUser} setNewUser={setNewUser} />
      ),
      enabledNext:
        !!newUser.name &&
        !!newUser.otp &&
        isValidPhoneNumber(newUser.phone) &&
        isValidPassword(newUser.password),
      nextAction: handleCheckOTP,
    },
    {
      label: (
        <>
          Nhập Thông Tin <br /> (Không Bắt Buộc)
        </>
      ),
      contentComponent: (
        <OptionalInput newUser={newUser} setNewUser={setNewUser} />
      ),
      actionComponent: (
        <RegistrationReview newUser={newUser} onRegister={handleSubmit} />
      ),
      enabledNext: true,
    },
  ];

  return (
    <FormContainer
      style={{ background: "linear-gradient(90deg, #0095C5 0%, #0DB966 100%)" }}
    >
      <FormWrapper
        title="Đăng ký"
        navigate={
          <>
            Bạn đã có tài khoản? <Link to="/login">Đăng nhập</Link>
          </>
        }
      >
        <SStepper
          steps={REGISTER_STEPS}
          activeStep={activeStep}
          handleNext={handleNext}
          handleBack={handleBack}
        />
      </FormWrapper>
    </FormContainer>
  );
};

export default RegisterPage;
