import UserInfo from "../Profile/UserInfo";
import SButton from "../Button/SButton";
import Box from "@mui/material/Box";
import { RegisterStepsProps } from "../../pages/RegisterPage";
import SFormDialog from "../FormDialog/SFormDialog";
import { useState } from "react";

type RegistrationReviewProps = Omit<RegisterStepsProps, "setNewUser"> & {
  onRegister: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<void>;
};

export default function RegistrationReview({
  newUser,
  onRegister,
}: RegistrationReviewProps) {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <SButton onClick={() => setOpen(true)}>HOÀN THÀNH</SButton>
      <SFormDialog
        title="Xác Nhận Thông Tin Và Nhấn Đăng Ký Tài Khoản"
        saveName="Đăng Ký Tài Khoản"
        open={open}
        close={() => setOpen(false)}
        onSave={(e) => {
          onRegister(e as React.MouseEvent<HTMLButtonElement, MouseEvent>);
        }}
      >
        <Box
          sx={{ mt: 3, mb: 1, width: "100%" }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="15px"
        >
          <UserInfo data={newUser} />
        </Box>
      </SFormDialog>
    </>
  );
}
