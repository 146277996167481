import * as React from "react";
import TablePagination from "@mui/material/TablePagination";
import { useMemo, useState } from "react";
import { Profile, User } from "../../types/type";
import UserService from "../../services/UserService";
import { useDebounce } from "../../hooks/useDebounce";
import SFormDialog from "../FormDialog/SFormDialog";
import { InputAdornment } from "@mui/material";
import SSelect from "../Select/SSelect";
import STable from "../Table/STable";
import { INITIAL_PROFILE, UM_TABLE_HEAD } from "../../untils/constants";
import {
  ActionContainer,
  Container,
  Title,
} from "../TransactionHistory/TransactionHistory";
import ProfilePage from "../../pages/ProfilePage";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import PasswordChangePage from "../../pages/PasswordChangePage";
import { useQuery } from "@tanstack/react-query";
import STextField from "../TextField/STextField";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import Moment from "react-moment";

export default function UserManagement() {
  const [openProfile, setOpenProfile] = useState(false);
  const [openPasswordChange, setOpenPasswordChange] = useState(false);
  const [keyword, setKeyword] = useState<string>("");
  const searchQuery = useDebounce(keyword, 1000);
  const [value, setValue] = useState<string>("oldest");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedUser, setSelectedUser] = useState<Profile>(INITIAL_PROFILE);

  const currentUser = useMemo(() => {
    return JSON.parse(localStorage.getItem("currentUser") || "{}");
  }, []);

  //Pagination
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getUserListParams = useMemo(() => {
    return {
      params: {
        current: page + 1,
        limit: rowsPerPage,
        sortField: value === "oldest" ? "" : "-createdAt",
        keyword: searchQuery,
      },
      token: currentUser.jwt,
    };
  }, [page, rowsPerPage, value, searchQuery]);

  const { data: users } = useQuery({
    queryKey: ["users", getUserListParams],
    queryFn: () => {
      return UserService.getUserList(
        getUserListParams.params,
        getUserListParams.token
      );
    },
  });

  //handle click
  const handleClickDetail = (user: User) => {
    setSelectedUser(user);
    setOpenProfile(true);
  };
  const handleClickChangePassword = (user: User) => {
    setSelectedUser(user);
    setOpenPasswordChange(true);
  };

  const ACTION_CELL_DATA = [
    {
      actionName: "Xem hồ sơ",
      icon: <PersonOutlinedIcon fontSize="small" />,
      buttonColor: "info",
      onClick: handleClickDetail,
      roles: { isAdmin: true },
    },
    {
      actionName: "Đổi mật khẩu",
      icon: <KeyOutlinedIcon fontSize="small" />,
      buttonColor: "warning",
      onClick: handleClickChangePassword,
      roles: { isAdmin: true },
    },
  ];

  const tableBodyData = useMemo(() => {
    return (users?.data || []).map((user) => {
      return {
        ...user,
        createdAt: <Moment format="YYYY-MM-DD HH:mm">{user.createdAt}</Moment>,
      };
    });
  }, [users?.data]);

  return (
    <Container>
      <Title>Quản Lý Người Dùng</Title>
      <ActionContainer>
        <STextField
          variant="outlined"
          size="small"
          sx={{ width: "40%" }}
          placeholder="Tìm kiếm theo tên, email hoặc số điện thoại"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
            sx: { borderRadius: 20 },
          }}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
          <SSelect
            size="small"
            options={[
              { value: "oldest", label: "Cũ nhất" },
              { value: "-createdAt", label: "Mới nhất" },
            ]}
            value={value}
            onChangeValue={setValue}
          />
        </div>
      </ActionContainer>

      {/* Table */}
      <STable
        tableHeadData={UM_TABLE_HEAD}
        tableBodyData={tableBodyData}
        actionCellData={ACTION_CELL_DATA}
      />
      {/* Dialog */}
      <SFormDialog
        title=""
        open={openProfile}
        close={() => setOpenProfile(false)}
        hasDialogActions={false}
      >
        <ProfilePage setOpen={setOpenProfile} userId={selectedUser._id} />
      </SFormDialog>
      <SFormDialog
        title=""
        open={openPasswordChange}
        close={() => setOpenPasswordChange(false)}
        hasDialogActions={false}
      >
        <PasswordChangePage
          userInfo={{ _id: selectedUser._id, name: selectedUser.name }}
          setOpen={setOpenPasswordChange}
        />
      </SFormDialog>

      {/* Pagination */}
      <TablePagination
        sx={{ flex: "0 1 52px", mt: "-20px" }}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={Number(users?.total) || 0}
        rowsPerPage={rowsPerPage}
        page={!users?.total ? 0 : page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  );
}
