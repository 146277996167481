import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { Link, useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import styled from "styled-components";
import logo from "../../assets/logo.png";
import { useMemo, useState } from "react";
import { Center } from "../Agreement/AgreementContent/AgreementContent";
import Drawer from "@mui/material/Drawer";
import MenuList from "../SideBar/MenuList";
import SMenu from "../Menu/SMenu";
import { MenuItemAction } from "../../types/type";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

export const Logo = styled.img`
  width: 60px;
  height: 60px;
`;

export default function THTopBar() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isShowSideMenu, setIsShowSideMenu] = useState<boolean>(false);

  const currentUser = useMemo(() => {
    return JSON.parse(localStorage.getItem("currentUser") || "{}");
  }, []);

  const navigate = useNavigate();

  const toggleDrawer = (
    open: boolean,
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setIsShowSideMenu(open);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = async () => {
    await UserService.logout(currentUser.jwt).then((res) => {
      localStorage.removeItem("currentUser");
      navigate("/login");
    });
  };

  const MenuData: Array<MenuItemAction> = [
    {
      actionItem: <AccountCircle />,
      actionName: "Thông tin tài khoản",
      onClickMenuItem: () => navigate("/profile"),
    },
    {
      actionItem: <AccountBalanceOutlinedIcon />,
      actionName: "Cập nhật tài khoản ngân hàng",
      onClickMenuItem: () => navigate("/bank-account"),
    },
    {
      actionItem: <KeyOutlinedIcon />,
      actionName: "Đổi mật khẩu",
      onClickMenuItem: () => navigate("/password-change"),
    },
    {
      actionItem: <LogoutOutlinedIcon />,
      actionName: "Đăng xuất",
      onClickMenuItem: () => logout(),
    },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          background: "linear-gradient(90deg, #0095C5 0%, #0DB966 100%)",
          "& .MuiToolbar-root.css-hyum1k-MuiToolbar-root": {
            padding: "4px 24px 4px 12px",
          },
        }}
      >
        <Toolbar>
          <React.Fragment>
            {currentUser.isAdmin && (
              <IconButton
                size="large"
                color="inherit"
                onClick={(e) => toggleDrawer(true, e)}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Drawer
              anchor="left"
              open={isShowSideMenu}
              onClose={(e: any) => toggleDrawer(false, e)}
            >
              <MenuList toggleDrawer={toggleDrawer} />
            </Drawer>
          </React.Fragment>

          <Link to={"/"}>
            <Logo src={logo} />
          </Link>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Trung Gian Giao Dịch
          </Typography>
          <Center>
            <IconButton color="inherit" sx={{ marginRight: "-10px" }}>
              <div style={{ fontSize: "14px" }}>0 VNĐ</div>
            </IconButton>
            <IconButton size="large" color="inherit">
              <AccountBalanceWalletIcon />
            </IconButton>
            <IconButton size="large" color="inherit">
              <NotificationsNoneIcon />
            </IconButton>
            <Center onClick={handleMenu}>
              <IconButton size="large" color="inherit">
                <AccountCircle />
              </IconButton>
              <Typography sx={{ marginLeft: "-5px", cursor: "pointer" }}>
                {currentUser.name}
              </Typography>
            </Center>
            <SMenu
              anchorEl={anchorEl}
              onClose={handleClose}
              menuItemActions={MenuData}
            />
          </Center>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
