import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { Step as StepType } from "../../types/type";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined";

interface SStepperProps {
  steps: StepType[];
  activeStep: number;
  handleNext: () => void;
  handleBack: () => void;
}

export default function SStepper({
  steps,
  activeStep,
  handleNext,
  handleBack,
}: SStepperProps) {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => {
          return (
            <Step
              sx={{
                "& .MuiStepLabel-labelContainer .MuiStepLabel-label": {
                  marginTop: "6px",
                },
              }}
              key={index}
            >
              <StepLabel>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <>
        <Box sx={{ mt: 3, mb: 1 }}>{steps[activeStep].contentComponent}</Box>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
            startIcon={
              <NavigateBeforeOutlinedIcon sx={{ marginTop: "-2px" }} />
            }
          >
            Lùi Lại
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          {activeStep === steps.length - 1 ? (
            steps[activeStep].actionComponent
          ) : (
            <Button
              onClick={(e) => {
                steps[activeStep].nextAction?.(e);
                if (!steps[activeStep].nextAction) handleNext();
              }}
              disabled={!steps[activeStep].enabledNext}
              endIcon={<NavigateNextOutlinedIcon sx={{ marginTop: "-2px" }} />}
            >
              Kế Tiếp
            </Button>
          )}
        </Box>
      </>
    </Box>
  );
}
