import { Container } from "../TransactionHistoryPage";
import THTopBar from "../../components/TransactionHistory/THTopBar";
import { useMemo } from "react";
import { Navigate } from "react-router-dom";
import FeeRateManagement from "../../components/FeeRateManagement/FeeRateManagement";

const FeeRateManagementPage = () => {
  const currentUser = useMemo(() => {
    return JSON.parse(localStorage.getItem("currentUser") || "{}");
  }, []);
  if (!currentUser.isAdmin) {
    return <Navigate to="/" />;
  }
  return (
    <Container>
      <THTopBar />
      <FeeRateManagement />
    </Container>
  );
};

export default FeeRateManagementPage;