import "react-quill/dist/quill.snow.css";
import AgreementMembers from "../components/Agreement/AgreementMembers/AgreementMembers";
import AgreementContent from "../components/Agreement/AgreementContent/AgreementContent";
import styled from "styled-components";
import AgreementChat from "../components/Agreement/AgreementChat/AgreementChat";
import { useEffect, useMemo, useRef, useState } from "react";
import TransactionService from "../services/TransactionService";
import { useLocation } from "react-router-dom";
import { Socket, io } from "socket.io-client";
import { SOCKET_URL } from "../services/config";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { INITIAL_TRANSACTION } from "../untils/constants";
import {
  ContractStateEnum,
  Message,
  MessageEnum,
  Profile,
  Transaction,
} from "../types/type";
import { toast } from "react-toastify";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  @media screen and (max-width: 1050px) {
    width: 1050px;
    overflow-y: auto;
  }
`;

const AgreementPage = () => {
  const queryClient = useQueryClient();
  const [socket, setSocket] = useState<Socket | null>(null);
  const messagesEnd = useRef<HTMLDivElement>(null);

  //get initial time transactionId
  const location = useLocation();
  const transactionId = location?.state?.id;

  //Memo hook
  const currentUser = useMemo(() => {
    return JSON.parse(localStorage.getItem("currentUser") || "{}");
  }, []);

  //query transaction
  const transactionQuery = useQuery({
    queryKey: ["transaction", transactionId],
    queryFn: () =>
      TransactionService.getTransactionDetail(transactionId, currentUser.jwt),
    onSuccess: (data) => {
      (data.membersA || []).unshift(data.adminA || {});
      (data.membersB || []).unshift(data.adminB || {});
    },
  });
  const transaction = transactionQuery.data || INITIAL_TRANSACTION;
  const membersA = transaction.membersA;
  const membersB = transaction.membersB;
  const conversations = transaction.conversations;
  const transactionAdmin = {
    isAdminA: currentUser._id === transaction.adminA._id,
    isAdminB: currentUser._id === transaction.adminB._id,
    isTransactionAdmin:
      currentUser._id === transaction.adminA._id ||
      currentUser._id === transaction.adminB._id,
  };
  const contractState = {
    isConfirmed: transaction.contractState === ContractStateEnum.Confirmed,
    isWaitA: transaction.contractState === ContractStateEnum.WaitA,
    isWaitB: transaction.contractState === ContractStateEnum.WaitB,
    isDrafting: transaction.contractState === ContractStateEnum.Drafting,
  };

  //handle Update Cached Data
  const handleUpdateCachedData = (
    key: keyof Transaction,
    data: any,
    type?: "delete"
  ) => {
    queryClient.setQueryData<Transaction>(
      ["transaction", transactionId],
      (oldData) => {
        if (oldData) {
          //if delete members
          if (type) {
            const remainingMembers = (oldData[key] as Profile[]).filter(
              (member) => member._id !== data._id
            );
            return {
              ...oldData,
              [key]: remainingMembers,
            };
          }
          //default
          return {
            ...oldData,
            [key]:
              Array.isArray(oldData[key]) && !type
                ? [...(oldData[key] as Profile[] | Message[]), data]
                : data,
          };
        }
        return oldData; //oldData is undefined
      }
    );
  };

  //soketio client
  useEffect(() => {
    setSocket(
      io(SOCKET_URL, {
        auth: {
          token: currentUser.jwt,
        },
      })
    );
    return () => {
      socket?.disconnect();
    };
  }, []);

  useEffect(() => {
    socket?.on("connect", () => {
      socket?.emit("transactionView", { transactionID: location?.state?.id });
      socket.on("messagea", (data) => {
        const message = {
          message: data.message,
          senderID: data.sender._id,
          senderName: data.sender.name,
          messageType: MessageEnum.MessageA,
          createAt: data.createAt,
        };
        handleUpdateCachedData("conversations", message);
      });
      socket.on("messageb", (data) => {
        const message = {
          message: data.message,
          senderID: data.sender._id,
          senderName: data.sender.name,
          messageType: MessageEnum.MessageB,
          createAt: data.createAt,
        };
        handleUpdateCachedData("conversations", message);
      });
      socket.on("contractContent", (data) => {
        queryClient.setQueryData<Transaction>(
          ["transaction", transactionId],
          (oldData) => {
            return oldData
              ? {
                  ...oldData,
                  contract: data.content,
                  amountOfMoney: data.amountOfMoney,
                  depositRate: data.depositRate,
                }
              : oldData;
          }
        );
      });
      socket.on("confirmContract", (data) => {
        handleUpdateCachedData("contractState", data.contractState);
        const cachedTransaction = queryClient.getQueryData([
          "transaction",
          transactionId,
        ]) as Transaction;
        if (cachedTransaction.contractState === ContractStateEnum.Confirmed) {
          toast.success(`Hợp đồng đã được ký từ cả hai bên`);
        }
      });
      socket.on("cancelContract", () => {
        toast.info(
          `Một trong hai bên đã hủy ký hợp đồng. Hãy thỏa thuận lại hợp đồng. Sau đó nhấn vào nút "Ký Hợp Đồng Và Gửi Yêu Cầu Đồng Ý Tới Bên Còn Lại"`
        );
        handleUpdateCachedData("contractState", ContractStateEnum.Drafting);
      });
      socket.on("transactionState", (data) => {
        handleUpdateCachedData("transactionState", data.state);
      });
      socket.on("addMemberA", (data) => {
        handleUpdateCachedData("membersA", data.addedUser._doc);
      });
      socket.on("removeMemberA", (data) => {
        handleUpdateCachedData("membersA", data.removedUser._doc, "delete");
      });
      socket.on("addMemberB", (data) => {
        handleUpdateCachedData("membersB", data.addedUser._doc);
      });
      socket.on("removeMemberB", (data) => {
        handleUpdateCachedData("membersB", data.removedUser._doc, "delete");
      });
    });
    return () => {
      socket?.disconnect();
    };
  }, [socket]);

  //scroll to bottom conversation
  const scrollToBottom = () => {
    messagesEnd?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [transactionQuery.data]);

  return (
    <Container>
      <AgreementMembers
        title="Thành Viên Bên Mua"
        members={membersA}
        transactionId={transactionId}
        transactionAdmin={transactionAdmin}
        isLeft
      />
      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <AgreementContent
          isSuccess={transactionQuery.isSuccess}
          transaction={transactionQuery.data || INITIAL_TRANSACTION}
          contractState={contractState}
          transactionAdmin={transactionAdmin}
          onUpdateCachedData={handleUpdateCachedData}
        />
        <AgreementChat
          conversations={conversations}
          messagesEnd={messagesEnd}
          transactionId={transactionId}
        />
      </div>
      <AgreementMembers
        title="Thành Viên Bên Bán"
        members={membersB}
        transactionId={transactionId}
        transactionAdmin={transactionAdmin}
      />
    </Container>
  );
};

export default AgreementPage;
