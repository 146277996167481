import styled from "styled-components";
import { Link } from "react-router-dom";
import page404 from "../../assets/gnusmas_pageNotFound.webp";
import SButton from "../Button/SButton";
import logo from "../../assets/logo_sconnect.jpg";

const Container = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1050px) {
    width: 1050px;
    overflow-y: auto;
  }
`;

const Logo = styled.img`
  width: 237px;
  height: 100px;
`;

const ErrorInfo = styled.div`
  display: flex;
  height: 360px;
  width: 1000px;
`;

const TextInfo = styled.div`
  padding: 0 0 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  h1 {
    font-size: 48px;
  }
  p {
    margin-top: 15px;
  }
`;

const NotFound = () => {
  return (
    <Container>
      <Link
        to={"/"}
        style={{
          color: "#000000",
          textDecoration: "none",
          position: "absolute",
          marginLeft: "auto",
          marginRight: "auto",
          left: 0,
          right: 0,
          top: "30px",
          textAlign: "center",
        }}
      >
        <Logo src={logo} />
      </Link>
      <ErrorInfo>
        <img src={page404} />
        <TextInfo>
          <div>
            <h1>Trang mà bạn yêu cầu hiện không sử dụng được</h1>
            <p>
              Xin lỗi, chúng tôi đang gặp sự cố khi thực hiện yêu cầu của bạn.
              <br />
              Có thể là link thường dùng của bạn đã cũ hoặc bạn gặp phải link
              hỏng.
              <br />
              Vui lòng tham khảo liên kết về thông tin.
            </p>
          </div>
          <div>
            <SButton color="secondary">
              <Link
                to="/"
                style={{ textDecoration: "none", color: "#ffffff" }}
              >
                Đi đến Trang Chủ Trung Gian Giao Dịch
              </Link>
            </SButton>
          </div>
        </TextInfo>
      </ErrorInfo>
    </Container>
  );
};

export default NotFound;
