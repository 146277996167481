import IconButton from "@mui/material/IconButton";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import {
  TransactionAdmin,
  TransactionStateEnum,
  getTSVieToEng,
} from "../../../types/type";
import CheckIcon from "@mui/icons-material/Check";
import styled from "styled-components";
import { TRANSACTION_STATE_STEPS } from "../../../untils/constants";
import { useMemo } from "react";
import { useMutation } from "@tanstack/react-query";
import TransactionService from "../../../services/TransactionService";
import { alertStatus } from "../../../untils/untils";
import { Tooltip } from "@mui/material";

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface TransactionStateProps {
  transactionId: string;
  transactionState: TransactionStateEnum;
  transactionAdmin: TransactionAdmin;
}

const TransactionState = ({
  transactionId,
  transactionState,
  transactionAdmin,
}: TransactionStateProps) => {
  //Memo
  const currentUser = useMemo(() => {
    return JSON.parse(localStorage.getItem("currentUser") || "{}");
  }, []);
  const transactionStateIndex = useMemo(() => {
    return TRANSACTION_STATE_STEPS.findIndex(
      (step) => getTSVieToEng(step) === transactionState
    );
  }, [transactionState]);

  //update transaction state
  const updateTransactionStateMutation = useMutation({
    mutationFn: (transactionState: TransactionStateEnum) => {
      return TransactionService.updateTransactionState(
        transactionId,
        transactionState,
        currentUser.jwt
      );
    },
  });
  const updateTransactionState = (transactionState: TransactionStateEnum) => {
    updateTransactionStateMutation.mutate(transactionState, {
      onError: (error: any) => {
        const errorMessage = error.response.data.message;
        alertStatus(errorMessage);
      },
    });
  };
  const handleUpdateTransactionState = (transactionVieState: string) => {
    if (
      (transactionAdmin.isAdminB &&
        getTSVieToEng(transactionVieState) === TransactionStateEnum.Sent) ||
      (transactionAdmin.isAdminA &&
        getTSVieToEng(transactionVieState) === TransactionStateEnum.Received) ||
      currentUser.isAdmin
    ) {
      updateTransactionState(getTSVieToEng(transactionVieState));
    }
  };

  return (
    <Container>
      <Stepper
        alternativeLabel
        sx={{
          flex: 1, //dãn ra bằng chiều rộng container
          "& .MuiStepLabel-labelContainer .MuiStepLabel-label": {
            marginTop: "2px",
          },
        }}
      >
        {TRANSACTION_STATE_STEPS.map((transactionVieState, index) => (
          <Step key={transactionVieState}>
            <StepLabel
              sx={{
                "&.MuiStepLabel-root .Mui-disabled": {
                  color: "#000000",
                },
                marginTop: "2px",
              }}
              icon={
                <Tooltip
                  title={`Chuyển trạng thái giao dịch thành ${transactionVieState}`}
                  arrow={true}
                >
                  <IconButton
                    onClick={() => {
                      handleUpdateTransactionState(transactionVieState);
                    }}
                    sx={{
                      "&.MuiIconButton-root:hover": {
                        background:
                          index <= transactionStateIndex
                            ? "#1DA462"
                            : "#9E9E9E",
                      },
                      padding: 0,
                      borderRadius: "50%",
                      background:
                        index <= transactionStateIndex ? "#1DA462" : "#9E9E9E",
                      width: "24px",
                      height: " 24px",
                      color: "#ffffff",
                      fontSize: "14px",
                    }}
                  >
                    {index <= transactionStateIndex ? (
                      <CheckIcon fontSize="small" sx={{ color: "#ffffff" }} />
                    ) : (
                      index + 1
                    )}
                  </IconButton>
                </Tooltip>
              }
            >
              {transactionVieState}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Container>
  );
};

export default TransactionState;
