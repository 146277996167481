import styled from "styled-components";
import Messages from "./Messages";
import InputBox from "./InputBox";
import { Message, Transaction, User } from "../../../types/type";
import { useState } from "react";
import { Alert } from "@mui/material";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: overlay;
  overflow-x: hidden;
`;

export interface AgreementChatProps {
  conversations: Array<Message>;
  messagesEnd: React.RefObject<HTMLDivElement>;
  transactionId: string;
}

const AgreementChat = ({ conversations, messagesEnd, transactionId }: AgreementChatProps) => {
  return (
    <Container>
      <Messages conversations={conversations} messagesEnd={messagesEnd} />
      <InputBox transactionId={transactionId} />
    </Container>
  );
};

export default AgreementChat;
