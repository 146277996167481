import { User } from "../types/type";
import STextField from "./TextField/STextField";
import { InputWrapper } from "./Register/RequiredInput";
import { isValidEmail } from "../untils/untils";

export interface EmailInputProps {
  data:
    | (Omit<User, "jwt"> & { otp: number | string })
    | (Pick<User, "email"> & { otp: number | string; newPassword: string });
  setFunction: (value: string) => void;
}

export default function EmailInput({ data, setFunction }: EmailInputProps) {
  return (
    <InputWrapper>
      <STextField
        required
        label="Email"
        value={data.email}
        onChange={(e) => setFunction(e.target.value)}
        isEmty={data.email === ""}
        isValid={isValidEmail(data.email)}
        messageHelper={
          isValidEmail(data.email)
            ? ""
            : "Định dạng email đúng: example@example.com"
        }
      />
    </InputWrapper>
  );
}
