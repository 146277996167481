import { configureStore } from "@reduxjs/toolkit";
import pageCacheReducer from "./pageCacheSlice";

export const store = configureStore({
  reducer: {
    pageCache: pageCacheReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;