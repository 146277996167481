import styled from "styled-components";
import ReactQuill from "react-quill";
import waterMark from "../../../assets/water-mark-confirmed.png";
import ContractActions from "./ContractActions";
import { AgreementContentProps } from "./AgreementContent";

const Container = styled.div<{ $isConfirmed: boolean }>`
  flex-grow: 1;
  position: relative;
  //100% - (heightOfTransactionHeader + heightOfTransactionFeeAndStateWrapper)
  height: calc(100% - 166px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .quill {
    margin-bottom: 10px;
  }
  .ql-editor {
    position: relative;
    z-index: 2;
    padding: 35px 20px 15px 20px;
    background-color: ${(props) =>
      props.$isConfirmed ? "rgb(238, 240, 241, 0.5)" : "#ffffff"};
  }
  .ql-container.ql-snow,
  .ql-toolbar.ql-snow {
    border: none;
  }
  .ql-toolbar.ql-snow {
    border-bottom: 1px solid #ccc;
  }
`;

const ContractTitle = styled.div<{ $isConfirmed: boolean }>`
  height: 23px;
  padding-top: 6px;
  background: ${(props) =>
    props.$isConfirmed ? "rgb(238, 240, 241, 0.5)" : "#ffffff"};
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 41px;
  text-align: center;
  z-index: 1000;
`;
const WaterMark = styled.img`
  width: 167px;
  height: 150px;
  bottom: 20px;
  right: 50px;
  position: absolute;
  z-index: 1;
`;

export interface ContractEditorProps
  extends Omit<AgreementContentProps, "transaction"> {
  transactionId: string;
  contractContent: string;
  amountOfMoney: string | number;
  depositRate: string | number;
}

export default function ContractEditor({
  isSuccess,
  transactionId,
  contractContent,
  amountOfMoney,
  depositRate,
  transactionAdmin,
  contractState,
  onUpdateCachedData,
}: ContractEditorProps) {
  return (
    <Container $isConfirmed={contractState.isConfirmed}>
      <ContractTitle $isConfirmed={contractState.isConfirmed}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "3px",
            fontSize: 14,
          }}
        >
          <div>CÁC ĐIỀU KHOẢN HAI BÊN THỐNG NHẤT</div>
        </div>
      </ContractTitle>
      <ContractActions
        isSuccess={isSuccess}
        transactionId={transactionId}
        contractContent={contractContent}
        amountOfMoney={amountOfMoney}
        depositRate={depositRate}
        contractState={contractState}
        transactionAdmin={transactionAdmin}
      />
      <WaterMark
        src={waterMark}
        style={{ display: contractState.isConfirmed ? "inline" : "none" }}
      />
      <ReactQuill
        readOnly={contractState.isConfirmed}
        theme="snow"
        value={contractContent}
        onChange={(value) => {
          onUpdateCachedData("contract", value);
        }}
        style={{ height: "calc(100% - 50px)" }}
      />
    </Container>
  );
}
