import axios from "axios";
import { Transaction, TransactionStateEnum } from "../types/type";
import { BASE_URL } from "./config";
import qs from "qs";

export default {
  getTransactionDetail: async (transactionId: string, token: string) => {
    try {
      const res = await axios.get<Transaction>(
        `${BASE_URL}/transactions/detail/${transactionId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  deleteTransaction: async (transactionId: string, token: string) => {
    try {
      const res = await axios.delete(
        `${BASE_URL}/transactions/${transactionId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  addMembersA: async (
    transactionId: string,
    memberID: string,
    token: string
  ) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/transactions/membersa/${transactionId}`,
        qs.stringify({ memberID }),
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  addMembersB: async (
    transactionId: string,
    memberID: string,
    token: string
  ) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/transactions/membersb/${transactionId}`,
        qs.stringify({ memberID }),
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  deleteMemberA: async (
    transactionId: string,
    memberID: string,
    token: string
  ) => {
    try {
      const res = await axios.delete(
        `${BASE_URL}/transactions/membersa/${transactionId}`,
        {
          params: { memberID },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  deleteMemberB: async (
    transactionId: string,
    memberID: string,
    token: string
  ) => {
    try {
      const res = await axios.delete(
        `${BASE_URL}/transactions/membersb/${transactionId}`,
        {
          params: { memberID },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  updateContractContent: async (
    transactionId: string,
    contract: {
      contractContent: string;
      amountOfMoney: string | number;
      depositRate: string | number;
    },
    token: string
  ) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/transactions/contractcontent/${transactionId}`,
        qs.stringify(contract),
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  confirmContract: async (transactionId: string, token: string) => {
    try {
      const res = await axios.put<Transaction>(
        `${BASE_URL}/transactions/confirmcontract/${transactionId}`,
        qs.stringify({}),
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  cancelConfirmContract: async (transactionId: string, token: string) => {
    try {
      const res = await axios.put<Transaction>(
        `${BASE_URL}/transactions/cancelconfirm/${transactionId}`,
        qs.stringify({}),
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  sendMessage: async (
    message: string,
    transactionId: string,
    token: string
  ) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/transactions/message/${transactionId}`,
        qs.stringify({ message }),
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  updateTransactionState: async (
    transactionId: string,
    transactionState: TransactionStateEnum,
    token: string
  ) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/transactions/state/${transactionId}`,
        qs.stringify({ transactionState }),
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
};
