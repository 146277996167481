import styled from "styled-components";
import { MenuItemAction, Profile, TransactionAdmin } from "../../../types/type";
import STextField from "../../TextField/STextField";
import SearchResultList from "../../TransactionHistory/SearchResultList";
import { useMemo, useState } from "react";
import { useDebounce } from "../../../hooks/useDebounce";
import UserService from "../../../services/UserService";
import TransactionService from "../../../services/TransactionService";
import InputAdornment from "@mui/material/InputAdornment";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { alertStatus } from "../../../untils/untils";

const Container = styled.div`
  width: 20%;
  height: 100vh;
  border: 1px solid #ccc;
  padding: 5px 5px 10px 5px;
  display: flex;
  flex-direction: column;
`;
const MemberSearchingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const MemberListWrapper = styled.div`
  ol {
    margin-left: 20px;
  }
`;

export interface AgreementMembersProps {
  title: string;
  isLeft?: boolean;
  members: Array<Profile>;
  transactionId: string;
  transactionAdmin: TransactionAdmin;
}

const AgreementMembers = ({
  title,
  isLeft,
  members,
  transactionId,
  transactionAdmin,
}: AgreementMembersProps) => {
  const [selectedMemberId, setSelectedMemberId] = useState<string>("");
  const [keyWord, setKeyWord] = useState<string>("");
  const searchQuery = useDebounce(keyWord, 1000);

  const currentUser = useMemo(() => {
    return JSON.parse(localStorage.getItem("currentUser") || "{}");
  }, []);

  //Find users query
  const userQuery = useQuery({
    queryKey: ["user", searchQuery],
    queryFn: () => {
      if (!searchQuery) return new Promise<void>((resolve, reject) => {});
      return UserService.findUser(searchQuery, currentUser.jwt);
    },
    onSuccess: () => {},
  });

  //Add a user mutation
  const userMutation = useMutation({
    mutationFn: (data: {
      addFn: "addMembersA" | "addMembersB";
      memberID: string;
    }) => {
      return TransactionService[data.addFn](
        transactionId,
        data.memberID,
        currentUser.jwt
      );
    },
    onSuccess: () => {
      toast.success("Thêm thành công");
    },
    onError: (error: any) => {
      const errorMessage = error.response.data.message;
      alertStatus(errorMessage);
    },
  });
  //Handle add a member
  const handleSelectedItem = (memberID: string) => {
    setKeyWord("");
    if (isLeft) {
      userMutation.mutate({ addFn: "addMembersA", memberID });
    } else {
      userMutation.mutate({ addFn: "addMembersB", memberID });
    }
  };

  // Delete a member mutation
  const deleteMemberMutation = useMutation({
    mutationFn: (data: {
      deleteFn: "deleteMemberA" | "deleteMemberB";
      memberID: string;
    }) => {
      return TransactionService[data.deleteFn](
        transactionId,
        data.memberID,
        currentUser.jwt
      );
    },
    onSuccess: () => {
      toast.success("Xoá thành công");
    },
    onError: (error: any) => {
      const errorMessage = error.response.data.message;
      alertStatus(errorMessage);
    },
  });
  //Handle delete a member
  const handleDeleteMember = () => {
    if (isLeft) {
      deleteMemberMutation.mutate({
        deleteFn: "deleteMemberA",
        memberID: selectedMemberId,
      });
    } else {
      deleteMemberMutation.mutate({
        deleteFn: "deleteMemberB",
        memberID: selectedMemberId,
      });
    }
  };

  const menuItemActions: Array<MenuItemAction> = [
    {
      actionName: "Xoá",
      onClickMenuItem: handleDeleteMember,
    },
  ];

  return (
    <Container>
      <MemberSearchingWrapper>
        <Title>{title}</Title>
        <STextField
          placeholder="Email hoặc phone"
          size="small"
          fullWidth
          disableBorder
          value={keyWord}
          onChange={(e) => setKeyWord(e.target.value)}
          sx={{ backgroundColor: "#EEF0F1", borderRadius: "5px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon fontSize="small" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <PersonAddAltOutlinedIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      </MemberSearchingWrapper>
      {/* display search results for members */}
      <SearchResultList
        data={userQuery.data}
        onSlectedItem={handleSelectedItem}
        fullSubInfo
        hasBorder
      />
      {/* display member list */}
      <MemberListWrapper>
        <SearchResultList
          data={members}
          fullHeight
          highlightFirstItem
          hasSecondaryAction
          onSlectedItem={(value: string) => {
            setSelectedMemberId(value);
          }}
          menuItemActions={menuItemActions}
          showMenuItemActions={transactionAdmin.isTransactionAdmin}
        />
      </MemberListWrapper>
    </Container>
  );
};

export default AgreementMembers;
