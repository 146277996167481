import axios from "axios";
import {
  Profile,
  Transaction,
  UpdateBankAccountFormType,
  User,
} from "../types/type";
import { BASE_URL, HEADERS } from "./config";
import qs from "qs";

// axios.defaults.withCredentials = true

export default {
  register: async (
    data: Omit<
      User,
      "jwt" | "isActive" | "isAdmin" | "createdAt" | "updatedAt" | "_id"
    > & { otp: string | number }
  ) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/users/register`,
        qs.stringify(data),
        HEADERS
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  sendOTP: async (email: string, otpType: "register" | "general") => {
    const res = await axios.post(
      `${BASE_URL}/otp/sendOTP`,
      qs.stringify({ email, otpType }),
      HEADERS
    );
    return res.data;
  },
  checkOTP: async (params: { email: string; otp: number | string }) => {
    const res = await axios.get<string>(`${BASE_URL}/otp/checkOTP`, {
      params,
    });
    return res.data;
  },
  forgotPassword: async (
    data: Pick<User, "email"> & { otp: number | string; newPassword: string }
  ) => {
    const res = await axios.post(
      `${BASE_URL}/users/createPassword`,
      qs.stringify(data),
      HEADERS
    );
    return res.data;
  },
  login: async (data: Pick<User, "email" | "password">) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/users/auth`,
        qs.stringify(data),
        HEADERS
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  getProfile: async (userId: string, token: string) => {
    const res = await axios.get<Profile>(
      `${BASE_URL}/users/profile/${userId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res.data;
  },
  updateBankAccount: async (
    params: Pick<User, "_id" | "jwt"> & {
      updateBankAccountFormData: UpdateBankAccountFormType;
    }
  ) => {
    const res = await axios.put(
      `${BASE_URL}/users/bank/${params._id}`,
      qs.stringify(params.updateBankAccountFormData),
      {
        headers: { Authorization: `Bearer ${params.jwt}` },
      }
    );
    return res.data;
  },
  updateProfile: async (userId: string, profile: Profile, token: string) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/users/profile/${userId}`,
        qs.stringify(profile),
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  logout: async (token: string) => {
    try {
      const res = await axios.get(`${BASE_URL}/users/logout`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  findUser: async (keyword: string, token: string) => {
    try {
      const res = await axios.get(`${BASE_URL}/users/find`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { keyword: keyword },
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  createTransaction: async (
    data: { name: string; otherAdmin: string; isACreated: boolean },
    token: string
  ) => {
    try {
      const res = await axios.post(
        `${BASE_URL}/transactions/create`,
        qs.stringify(data),
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  getTransactionList: async (
    params: {
      current: number;
      limit: number;
      sortField: string;
      keyword: string;
    },
    token: string
  ) => {
    const res = await axios.get<{
      data: Transaction[];
      pageSize: number | string;
      success: boolean;
      total: number | string;
    }>(`${BASE_URL}/transactions/list`, {
      headers: { Authorization: `Bearer ${token}` },
      params: params,
    });
    return res.data;
  },
  changePassword: async (
    userId: string,
    data: { oldPassword: string; newPassword: string },
    token: string
  ) => {
    try {
      const res = await axios.put(
        `${BASE_URL}/users/changepass/${userId}`,
        qs.stringify(data),
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  getUserList: async (
    params: {
      current: number;
      limit: number;
      sortField: string;
      keyword: string;
    },
    token: string
  ) => {
    try {
      const res = await axios.get<{
        data: Profile[];
        pageSize: number | string;
        success: boolean;
        total: number | string;
      }>(`${BASE_URL}/users`, {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  },
};
