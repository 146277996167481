import FormWrapper from "../components/FormWrapper/FormWrapper";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";
import UserService from "../services/UserService";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { alertStatus, isValidEmail, isValidPassword } from "../untils/untils";
import { Step, User } from "../types/type";
import SStepper from "../components/Stepper/SStepper";
import EmailInput from "../components/EmailInput";
import { FormContainer } from "./RegisterPage";
import RequiredInput from "../components/ForgotPassword/RequiredInput";
import SButton from "../components/Button/SButton";
import Tooltip from "@mui/material/Tooltip";
import CheckIcon from "@mui/icons-material/Check";

const ForgotPasswordPage = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [forgotPasswordForm, setForgotPasswordForm] = useState<
    Pick<User, "email"> & { otp: number | string; newPassword: string }
  >({
    email: "",
    newPassword: "",
    otp: "",
  });
  const navigate = useNavigate();

  const sendOTPMutation = useMutation({
    mutationFn: (email: string) => {
      return UserService.sendOTP(email, "general");
    },
    onSuccess: () => {
      toast.success(
        `Mã OTP đã được gửi đến ${forgotPasswordForm.email}. Vui lòng kiểm tra Hộp thư đến để lấy mã OTP và nhập vào ô "Mã OTP"`
      );
      handleNext();
    },
    onError: (error: any) => {
      const errorMessage = error.response.data.message;
      alertStatus(errorMessage);
      if (errorMessage === "This account does not exist") {
        setActiveStep(0);
        return;
      }
      handleNext();
    },
  });

  const forgotPasswordMutation = useMutation({
    mutationFn: () => {
      return UserService.forgotPassword(forgotPasswordForm);
    },
    onSuccess: () => {
      navigate("/login");
      toast.success(
        "Đổi mật khẩu thành công. Đăng nhập ngay bằng mật khẩu mới"
      );
    },
    onError: (error: any) => {
      const errorMessage = error.response.data.message;
      alertStatus(errorMessage);
    },
  });

  if (
    Object.keys(JSON.parse(localStorage.getItem("currentUser") || "{}"))
      .length > 0
  ) {
    return <Navigate to="/" />;
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSendOTP = () => {
    sendOTPMutation.mutate(forgotPasswordForm.email);
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    forgotPasswordMutation.mutate();
  };

  const FORGOT_PASSWORD_STEPS: Step[] = [
    {
      label: (
        <>
          Nhập Email <br /> (Lấy Mã OTP)
        </>
      ),
      contentComponent: (
        <EmailInput
          data={forgotPasswordForm}
          setFunction={(value) => {
            setForgotPasswordForm({ ...forgotPasswordForm, email: value });
          }}
        />
      ),
      enabledNext:
        !!forgotPasswordForm.email && isValidEmail(forgotPasswordForm.email),
      nextAction: handleSendOTP,
    },
    {
      label: (
        <>
          Nhập Thông Tin <br /> (Bắt Buộc)
        </>
      ),
      contentComponent: (
        <RequiredInput
          forgotPasswordForm={forgotPasswordForm}
          setForgotPasswordForm={setForgotPasswordForm}
        />
      ),
      enabledNext:
        !!forgotPasswordForm.otp &&
        isValidPassword(forgotPasswordForm.newPassword),
      actionComponent: (
        <Tooltip title="Lấy Mật Khẩu" arrow>
          <div>
            <SButton
              onClick={handleSubmit}
              disabled={
                !forgotPasswordForm.otp ||
                !isValidPassword(forgotPasswordForm.newPassword)
              }
            >
              <CheckIcon fontSize="small" />
            </SButton>
          </div>
        </Tooltip>
      ),
    },
  ];

  return (
    <FormContainer
      style={{ background: "linear-gradient(90deg, #0095C5 0%, #0DB966 100%)" }}
    >
      <FormWrapper
        title="Quên mật khẩu"
        navigate={
          <>
            Quay lại trang <Link to="/login">Đăng nhập</Link>
          </>
        }
      >
        <SStepper
          steps={FORGOT_PASSWORD_STEPS}
          activeStep={activeStep}
          handleNext={handleNext}
          handleBack={handleBack}
        />
      </FormWrapper>
    </FormContainer>
  );
};

export default ForgotPasswordPage;
