import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

interface MenuListProps {
  toggleDrawer: (
    open: boolean,
    event: React.KeyboardEvent | React.MouseEvent
  ) => void;
}

const ADMIN_MANAGEMENTS = [
  { icon: <ListAltOutlinedIcon />, text: "Quản Lý Giao Dịch", path: "/" },
  {
    icon: <PeopleAltOutlinedIcon />,
    text: "Quản Lý Người Dùng",
    path: "/admin/user-management",
  },
  {
    icon: <SettingsOutlinedIcon />,
    text: "Thiết Lập Tỉ Lệ Phí",
    path: "/admin/fee-rate-management",
  },
];

const MenuList = ({ toggleDrawer }: MenuListProps) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={(e) => toggleDrawer(false, e)}
      onKeyDown={(e) => toggleDrawer(false, e)}
    >
      <List>
        {ADMIN_MANAGEMENTS.map((data) => (
          <ListItem
            key={data.text}
            disablePadding
            onClick={() => navigate(data.path)}
          >
            <ListItemButton>
              <ListItemIcon>{data.icon}</ListItemIcon>
              <ListItemText primary={data.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default MenuList;
