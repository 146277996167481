import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  MenuItemAction,
  TransactionStateEnum,
  getTSVieToEng,
} from "../../types/type";
import { Divider, ListItemIcon, ListItemText } from "@mui/material";

export interface SMenuProps {
  anchorEl: null | HTMLElement;
  onClose: () => void;
  menuItemActions: Array<MenuItemAction>;
}

function SMenu({ onClose, anchorEl, menuItemActions }: SMenuProps) {
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorEl)}
      onClose={() => onClose()}
    >
      {menuItemActions.map((menuItem, index) => (
        <div key={menuItem.actionName as string}>
          <MenuItem
            onClick={(e) => {
              onClose();
              menuItem.onClickMenuItem(
                getTSVieToEng(menuItem.actionName as TransactionStateEnum)
              );
            }}
          >
            {menuItem.actionItem && (
              <ListItemIcon>{menuItem.actionItem}</ListItemIcon>
            )}
            <ListItemText>{menuItem.actionName}</ListItemText>
          </MenuItem>
          {index !== menuItemActions.length - 1 && <Divider />}
        </div>
      ))}
    </Menu>
  );
}

export default SMenu;
