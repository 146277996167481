import "./App.css";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import AgreementPage from "./pages/AgreementPage";
import TransactionHistoryPage from "./pages/TransactionHistoryPage";
import ProfilePage from "./pages/ProfilePage";
import PasswordChangePage from "./pages/PasswordChangePage";
import NotFound from "./components/NotFound/NotFound";
import UserManagementPage from "./pages/admin/UserManagementPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FeeRateManagementPage from "./pages/admin/FeeRateManagementPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import BankAccountPage from "./pages/BankAccountPage";

function App() {
  // axios.defaults.withCredentials = true;

  const ProtectedRoute = () => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
    if (Object.keys(currentUser).length <= 0) {
      return <Navigate to="/login" />;
    }
    return <Outlet />;
  };

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/" element={<ProtectedRoute />}>
            <Route index element={<TransactionHistoryPage />} />
            <Route path="agreement/:id" element={<AgreementPage />} />
            <Route path="profile" element={<ProfilePage />} />
            <Route path="bank-account" element={<BankAccountPage />} />
            <Route path="password-change" element={<PasswordChangePage />} />
            <Route
              path="admin/user-management"
              element={<UserManagementPage />}
            />
            <Route
              path="admin/fee-rate-management"
              element={<FeeRateManagementPage />}
            />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
