import Tooltip from "@mui/material/Tooltip";
import SButton from "../../Button/SButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import styled from "styled-components";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import TransactionService from "../../../services/TransactionService";
import { toast } from "react-toastify";
import { useEffect, useMemo, useState } from "react";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import SFormDialog from "../../FormDialog/SFormDialog";
import ReactQuill from "react-quill";
import { alertStatus } from "../../../untils/untils";
import { ContractEditorProps } from "./ContractEditor";
import { Transaction } from "../../../types/type";
import { INITIAL_TRANSACTION } from "../../../untils/constants";

const Container = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  display: flex;
  gap: 10px;
`;

type ContractActionsProps = Omit<ContractEditorProps, "onUpdateCachedData">;

export default function ContractActions({
  isSuccess,
  transactionId,
  contractContent,
  amountOfMoney,
  depositRate,
  contractState,
  transactionAdmin,
}: ContractActionsProps) {
  const queryClient = useQueryClient();
  const [isSendingConfirmDialog, setIsSendingConfirmDialog] =
    useState<boolean>(false);
  const [oldContract, setOldContract] =
    useState<Pick<Transaction, "contract" | "amountOfMoney" | "depositRate">>(
      INITIAL_TRANSACTION
    ); //to compare after and before contract content

  useEffect(() => {
    if (isSuccess) {
      const oldTransaction = queryClient.getQueryData([
        "transaction",
        transactionId,
      ]) as Transaction;

      setOldContract({
        contract: oldTransaction?.contract,
        amountOfMoney: oldTransaction?.amountOfMoney,
        depositRate: oldTransaction?.depositRate,
      });
    }
  }, [isSuccess]);

  //Memo
  const currentUser = useMemo(() => {
    return JSON.parse(localStorage.getItem("currentUser") || "{}");
  }, []);
  const isDisabledSaveContract = useMemo(() => {
    return (
      contractState.isConfirmed ||
      ((contractContent === oldContract.contract || contractContent === "") &&
        amountOfMoney == oldContract.amountOfMoney &&
        depositRate == oldContract.depositRate)
    );
  }, [contractState, contractContent, oldContract]);

  //update contract content
  const updateContractContentMutation = useMutation({
    mutationFn: (contract: {
      contractContent: string;
      amountOfMoney: string | number;
      depositRate: string | number;
    }) => {
      return TransactionService.updateContractContent(
        transactionId,
        contract,
        currentUser.jwt
      );
    },
  });
  const handleUpdateContractContent = () => {
    if (!transactionAdmin.isTransactionAdmin) {
      toast.error(
        "Lưu thất bại. Bạn không phải là thành viên hoặc Admin của giao dịch này"
      );
      return;
    }
    const contract = {
      contractContent,
      amountOfMoney,
      depositRate,
    };
    updateContractContentMutation.mutate(contract, {
      onSuccess: () => {
        setOldContract({
          ...contract,
          contract: contract.contractContent,
        });
        toast.success("Hợp đồng đã được lưu mới nhất");
      },
      onError: (error: any) => {
        const errorMessage = error.response.data.message;
        alertStatus(errorMessage);
      },
    });
  };

  //confirm contract
  const confirmContractMutation = useMutation({
    mutationFn: () => {
      return TransactionService.confirmContract(transactionId, currentUser.jwt);
    },
    onSuccess: () => {
      if (!contractState.isConfirmed) {
        toast.success(
          `Phía bạn đã ký hợp đồng thành công. Vui lòng chờ bên ${
            contractState.isWaitB ? "Bán" : "Mua"
          } xác nhận đồng ý`
        );
      }
    },
  });
  const handleConfirmContract = () => {
    confirmContractMutation.mutate();
  };

  //cancel confirm contract
  const cancelConfirmContractMutation = useMutation({
    mutationFn: () => {
      return TransactionService.cancelConfirmContract(
        transactionId,
        currentUser.jwt
      );
    },
  });
  const handleCancelContract = () => {
    cancelConfirmContractMutation.mutate();
  };

  //call confirm or cancel contract funtion
  const handleConfirm = () => {
    if (!transactionAdmin.isTransactionAdmin) {
      toast.error("Bạn không có quyền");
      return;
    }
    if (contractState.isWaitA || contractState.isWaitB) {
      handleCancelContract();
    } else {
      handleConfirmContract();
    }
  };

  //is sending confirm contract dialog
  useEffect(() => {
    if (contractState.isDrafting) {
      setIsSendingConfirmDialog(false);
      return;
    }
    if (contractState.isWaitB && transactionAdmin.isAdminB) {
      setIsSendingConfirmDialog(true);
      return;
    }
    if (contractState.isWaitA && transactionAdmin.isAdminA) {
      setIsSendingConfirmDialog(true);
      return;
    }
  }, [contractState]);

  //get button name
  const buttonName = useMemo(() => {
    if (contractState.isConfirmed) {
      return "Hợp đồng đã được hai bên ký";
    }
    if (contractState.isWaitA || contractState.isWaitB) {
      return "Huỷ ký hợp đồng để thỏa thuận lại";
    }
    return "Ký hợp đồng và gửi yêu cầu đồng ý tới bên còn lại";
  }, [contractState]);

  //get button icon
  const buttonIcon = useMemo(() => {
    if (contractState.isWaitA || contractState.isWaitB)
      return <CloseOutlinedIcon />;
    return <HandshakeOutlinedIcon />;
  }, [contractState]);

  return (
    <Container>
      <Tooltip title={buttonName} arrow>
        <div>
          <SButton
            variant="outlined"
            color={contractState.isDrafting ? "info" : "error"}
            disabled={contractState.isConfirmed}
            onClick={() => handleConfirm()}
          >
            {buttonIcon}
          </SButton>
        </div>
      </Tooltip>
      <Tooltip
        title={
          contractState.isConfirmed
            ? "Hợp đồng đã được hai bên ký"
            : isDisabledSaveContract
            ? "Nội dung hợp đồng đang là mới nhất. Hãy thay đổi sau đó bấm Lưu"
            : "Lưu nội dung hợp đồng mới nhất"
        }
        arrow
      >
        <div>
          <SButton
            disabled={isDisabledSaveContract}
            onClick={() => {
              handleUpdateContractContent();
            }}
          >
            <SaveOutlinedIcon />
          </SButton>
        </div>
      </Tooltip>

      <SFormDialog
        title={`${contractState.isWaitB ? "Bên Mua " : "Bên Bán "}
              Yêu Cầu Xác Nhận Đồng Ý Với Nội Dung: `}
        saveName="Xác Nhận Đồng Ý"
        cancelName="Huỷ Ký Hợp Đồng Để Thỏa Thuận Lại"
        isError={false}
        open={isSendingConfirmDialog}
        close={() => setIsSendingConfirmDialog(false)}
        onCancel={() => {
          handleCancelContract();
        }}
        onSave={() => {
          handleConfirmContract();
        }}
      >
        <ReactQuill
          style={{ border: "1px solid #ccc" }}
          readOnly
          theme="bubble"
          value={contractContent}
        />
      </SFormDialog>
    </Container>
  );
}
