import { BankAccountType } from "../../types/type";
import { Info, InfoContainer, InfoValue } from "../Profile/UserInfo";

interface BankAccountInfoProps {
  data: BankAccountType;
}

export default function BankAccountInfo({ data }: BankAccountInfoProps) {
  return (
    <div style={{ width: "100%" }}>
      <InfoContainer>
        <Info>Tên ngân hàng:</Info>
        <InfoValue> {data.bankName || ""}</InfoValue>
      </InfoContainer>

      <InfoContainer>
        <Info>Số tài khoản:</Info>
        <InfoValue> {data.accountNumber || ""}</InfoValue>
      </InfoContainer>

      <InfoContainer>
        <Info>Chủ tài khoản:</Info>
        <InfoValue> {data.accountOwner || ""}</InfoValue>
      </InfoContainer>
    </div>
  );
}
