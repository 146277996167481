import { createSlice } from "@reduxjs/toolkit";

interface PageCacheState {
  pagination: {
    currentPage: number;
    rowsPerPage: number;
  };
  searchQuery: string;
  sortOption: string;
}

const initialState: PageCacheState = {
  pagination: {
    currentPage: 0,
    rowsPerPage: 10,
  },
  searchQuery: "",
  sortOption: "oldest",
};

const pageCacheSlice = createSlice({
  name: "pageCache",
  initialState,
  reducers: {
    updatePagination: (state, action) => {
      state.pagination = action.payload;
    },
    updateSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    updateSortOption: (state, action) => {
      state.sortOption = action.payload;
    },
  },
});

export const { updatePagination, updateSearchQuery, updateSortOption } =
  pageCacheSlice.actions;

export default pageCacheSlice.reducer;
