import styled from "styled-components";
import SFormDialog from "../FormDialog/SFormDialog";
import STextField from "../TextField/STextField";
import { useEffect, useMemo, useState } from "react";
import UserService from "../../services/UserService";
import { useDebounce } from "../../hooks/useDebounce";
import { GroupEnum, Profile, User } from "../../types/type";
import InputAdornment from "@mui/material/InputAdornment";
import Chip from "@mui/material/Chip";
import SearchResultList from "./SearchResultList";
import Avatar from "@mui/material/Avatar";
import SButton from "../Button/SButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import { INITIAL_PROFILE } from "../../untils/constants";
import { Typography } from "@mui/material";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

interface CreateTransactionProps {}

export default function CreateTransaction({}: CreateTransactionProps) {
  const [open, setOpen] = useState(false);
  const [keyword, setKeyword] = useState<string>("");
  const searchQuery = useDebounce(keyword, 1000);
  const [searchResult, setSearchResult] = useState<Array<User>>([]);
  const [name, setName] = useState<string>("");
  const [adminB, setAdminB] = useState<Profile>(INITIAL_PROFILE);
  const [selectedValue, setSelectedValue] = useState<string>(GroupEnum.A);
  const navigate = useNavigate();

  const currentUser = useMemo(() => {
    return JSON.parse(localStorage.getItem("currentUser") || "{}");
  }, []);

  const isErrorForm = useMemo(() => {
    return !name || !adminB._id;
  }, [name, adminB]);

  useEffect(() => {
    if (searchQuery) {
      UserService.findUser(searchQuery, currentUser.jwt).then((res) => {
        setSearchResult(res);
      });
    } else {
      setSearchResult([]);
    }
  }, [searchQuery]);

  const resetForm = () => {
    setName("");
    setKeyword("");
    setAdminB(INITIAL_PROFILE);
    setSelectedValue(GroupEnum.A);
  };

  const createTransation = () => {
    UserService.createTransaction(
      {
        name,
        otherAdmin: adminB._id || "",
        isACreated: selectedValue === GroupEnum.A,
      },
      currentUser.jwt
    ).then((res) => {
      navigate(`/agreement/${res._id}`, {
        state: { id: res._id },
      });
    });
  };

  const handleSelectedItem = (value: any) => {
    setAdminB(value);
    setKeyword("");
  };

  return (
    <>
      <Tooltip title="Tạo giao dịch" arrow>
        <div>
          <SButton
            onClick={(e) => {
              resetForm();
              setOpen(true);
            }}
            color="info"
          >
            <AddIcon />
          </SButton>
        </div>
      </Tooltip>
      <SFormDialog
        title="Tạo Giao Dịch"
        saveName="Tạo"
        isError={isErrorForm}
        open={open}
        close={() => setOpen(false)}
        onSave={createTransation}
      >
        <>
          <Wrapper>
            <STextField
              label="Tên giao dịch"
              fullWidth
              sx={{ marginTop: "6px" }}
              value={name}
              onChange={(e) => setName(e.target.value)}
              isEmty={!name}
            />
            <STextField
              placeholder="Tìm kiếm đối tác (Email hoặc số điện thoại)"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              InputProps={{
                startAdornment: adminB._id && (
                  <InputAdornment position="start">
                    <Chip
                      avatar={<Avatar>A</Avatar>}
                      label={adminB.name}
                      onDelete={() => {
                        setAdminB(INITIAL_PROFILE);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <SearchResultList
              data={searchResult}
              onSlectedItem={handleSelectedItem}
              fullSubInfo
            />
          </Wrapper>
          <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <Typography fontWeight={500}>Bạn là:</Typography>
            <RadioGroup
              row
              value={selectedValue}
              name="Group"
              onChange={(event, newValue) => setSelectedValue(newValue)}
            >
              <FormControlLabel
                value={GroupEnum.A}
                control={<Radio />}
                label="Bên Mua"
              />
              <FormControlLabel
                value={GroupEnum.B}
                control={<Radio />}
                label="Bên Bán"
              />
            </RadioGroup>
          </div>
        </>
      </SFormDialog>
    </>
  );
}
