import { UpdateBankAccountFormType } from "../../../types/type";
import STextField from "../../TextField/STextField";

interface BankAccountInfoStepProps {
  value: UpdateBankAccountFormType;
  setValue: React.Dispatch<React.SetStateAction<UpdateBankAccountFormType>>;
}

export default function BankAccountInfoStep({
  value,
  setValue,
}: BankAccountInfoStepProps) {
  return (
    <>
      <STextField
        label="Tên ngân hàng"
        fullWidth
        value={value.bankName}
        onChange={(e) =>
          setValue({
            ...value,
            bankName: e.target.value,
          })
        }
        isEmty={value.bankName === ""}
        sx={{ marginTop: "10px" }}
      />

      <STextField
        label="Số tài khoản"
        fullWidth
        value={value.accountNumber}
        onChange={(e) =>
          setValue({
            ...value,
            accountNumber: e.target.value,
          })
        }
        isEmty={value.accountNumber === ""}
        sx={{ marginTop: "10px" }}
      />

      <STextField
        label="Chủ tài khoản"
        fullWidth
        value={value.accountOwner}
        onChange={(e) =>
          setValue({
            ...value,
            accountOwner: e.target.value,
          })
        }
        isEmty={value.accountOwner === ""}
        sx={{ marginTop: "10px" }}
      />
    </>
  );
}
