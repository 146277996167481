import { createContext, useReducer } from "react";
import { ContractStateEnum, Transaction } from "../types/type";
import { INITIAL_TRANSACTION } from "../untils/constants";

export const TransactionContext = createContext<TransactionContextType>(
  {} as TransactionContextType
);

export const TransactionProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(transactionReducer, INITIAL_TRANSACTION);
  return (
    <TransactionContext.Provider
      value={{ transactionContextData: state, dispatch }}
    >
      {children}
    </TransactionContext.Provider>
  );
};

const transactionReducer = (state: Transaction, action: any) => {
  switch (action.type) {
    case "INIT_MESSAGE":
      return {
        ...state,
        conversations: action.payload,
      };
    case "SENT_MESSAGE":
      return {
        ...state,
        conversations: [...(state.conversations || []), action.payload],
      };
    case "SAVE_CONTRACT":
      return {
        ...state,
        contract: action.payload,
      };
    case "SAVE_AMOUNT_OF_MONEY":
      return {
        ...state,
        amountOfMoney: action.payload,
      };
    case "SAVE_DEPOSIT_RATE":
      return {
        ...state,
        depositRate: action.payload,
      };
    case "CONFIRM_CONTRACT":
      return {
        ...state,
        contractState: action.payload,
      };
    case "CANCEL_CONTRACT":
      return {
        ...state,
        contractState: ContractStateEnum.Drafting,
      };
    case "INIT_MEMBERS":
      return {
        ...state,
        membersA: action.payload.membersA,
        membersB: action.payload.membersB,
      };
    case "ADD_MEMBER_A":
      return {
        ...state,
        membersA: [...(state.membersA || []), action.payload],
      };
    case "ADD_MEMBER_B":
      return {
        ...state,
        membersB: [...(state.membersB || []), action.payload],
      };
    default:
      return state;
  }
};

interface TransactionContextType {
  transactionContextData: Transaction;
  dispatch: React.Dispatch<any>;
}
