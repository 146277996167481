import styled from "styled-components";
import { Profile, User } from "../../types/type";

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 60px;
  font-size: 18px;
  border-bottom: 1px solid #ccc;
  height: 60px;
`;
export const Info = styled.div`
  flex: 1;
  font-weight: 600;
`;
export const InfoValue = styled.div`
  flex: 1;
`;

interface UserInfoProps {
  data: Profile | (Omit<User, "jwt"> & { otp: number | string });
}

export default function UserInfo({ data }: UserInfoProps) {
  return (
    <div style={{ width: "100%" }}>
      <InfoContainer>
        <Info>Họ và tên:</Info>
        <InfoValue> {data.name || ""}</InfoValue>
      </InfoContainer>

      <InfoContainer>
        <Info>Email:</Info>
        <InfoValue> {data.email || ""}</InfoValue>
      </InfoContainer>

      <InfoContainer>
        <Info>Phone:</Info>
        <InfoValue> {data.phone || ""}</InfoValue>
      </InfoContainer>

      <InfoContainer>
        <Info>Địa chỉ:</Info>
        <InfoValue> {data.address || ""}</InfoValue>
      </InfoContainer>

      <InfoContainer>
        <Info>Công ty:</Info>
        <InfoValue> {data.companyName || ""}</InfoValue>
      </InfoContainer>

      <InfoContainer>
        <Info>Chức vụ:</Info>
        <InfoValue> {data.position || ""}</InfoValue>
      </InfoContainer>
    </div>
  );
}
