import SFormDialog from "../FormDialog/SFormDialog";
import { Wrapper } from "../TransactionHistory/CreateTransaction";
import STextField from "../TextField/STextField";
import { FeeRate } from "../../types/type";
import { useMemo } from "react";

interface CreateOrEditFeeRateProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  feeRate: FeeRate;
  setFeeRate: React.Dispatch<React.SetStateAction<FeeRate>>;
  onAddOrEditFeeRate: () => void;
}

export default function CreateOrEditFeeRate({
  open,
  setOpen,
  feeRate,
  setFeeRate,
  onAddOrEditFeeRate,
}: CreateOrEditFeeRateProps) {
  const isErrorForm = useMemo(() => {
    return feeRate.amountFrom === "" || !feeRate.amountTo || !feeRate.feeRate;
  }, [feeRate]);

  return (
    <SFormDialog
      title={`${feeRate._id ? "Chỉnh Sửa" : "Tạo"} Tỉ Lệ Phí`}
      saveName="Tạo"
      isError={isErrorForm}
      open={open}
      close={() => setOpen(false)}
      onSave={onAddOrEditFeeRate}
    >
      <Wrapper>
        <STextField
          label="Tổng tiền từ (VNĐ)"
          fullWidth
          sx={{ marginTop: "6px" }}
          value={feeRate.amountFrom}
          onChange={(e) =>
            setFeeRate({ ...feeRate, amountFrom: e.target.value })
          }
          isEmty={feeRate.amountFrom === ""}
        />
        <STextField
          label="Tổng tiền đến (VNĐ)"
          fullWidth
          sx={{ marginTop: "6px" }}
          value={feeRate.amountTo}
          onChange={(e) => setFeeRate({ ...feeRate, amountTo: e.target.value })}
          isEmty={feeRate.amountTo === ""}
        />
        <STextField
          label="Tỉ lệ phí (%)"
          fullWidth
          sx={{ marginTop: "6px" }}
          value={feeRate.feeRate}
          onChange={(e) => setFeeRate({ ...feeRate, feeRate: e.target.value })}
          isEmty={feeRate.feeRate === ""}
        />
      </Wrapper>
    </SFormDialog>
  );
}
