import styled from "styled-components";
import { TextLogo } from "../components/FormWrapper/FormWrapper";
import { useMemo, useState } from "react";
import UserService from "../services/UserService";
import UpdateProfile from "../components/Profile/UpdateProfile";
import Tooltip from "@mui/material/Tooltip";
import SButton from "../components/Button/SButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useNavigate } from "react-router-dom";
import { INITIAL_PROFILE } from "../untils/constants";
import { useQuery } from "@tanstack/react-query";
import UserInfo from "../components/Profile/UserInfo";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px;
`;

export const Wrapper = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

interface ProfilePageProps {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  userId?: string;
}

const ProfilePage = ({ setOpen, userId }: ProfilePageProps) => {
  const [openUpdateProfile, setOpenUpdateProfile] = useState(false);
  const navigate = useNavigate();

  const currentUser = useMemo(() => {
    return JSON.parse(localStorage.getItem("currentUser") || "{}");
  }, []);

  const { data: profile } = useQuery({
    queryKey: ["profile", userId ? userId : currentUser._id],
    queryFn: () =>
      UserService.getProfile(
        userId ? userId : currentUser._id,
        currentUser.jwt
      ),
  });

  return (
    <Container>
      <Wrapper>
        <TextLogo>Thông Tin Cá Nhân</TextLogo>
        <UserInfo data={profile || INITIAL_PROFILE} />
        {/* update profile */}
        <div style={{ display: "flex", gap: "20px" }}>
          {userId ? (
            <Tooltip title="Đóng">
              <div>
                <SButton color="error" onClick={(e) => setOpen?.(false)}>
                  <CloseOutlinedIcon fontSize="small" />
                </SButton>
              </div>
            </Tooltip>
          ) : (
            <Tooltip title="Quay lại">
              <div>
                <SButton color="secondary" onClick={(e) => navigate("/")}>
                  <ArrowBackIcon fontSize="small" />
                </SButton>
              </div>
            </Tooltip>
          )}
          <Tooltip title="Chỉnh sửa">
            <div>
              <SButton onClick={(e) => setOpenUpdateProfile(true)} color="info">
                <EditOutlinedIcon fontSize="small" />
              </SButton>
            </div>
          </Tooltip>
        </div>
        <UpdateProfile
          profile={profile || INITIAL_PROFILE}
          open={openUpdateProfile}
          setOpen={setOpenUpdateProfile}
          userId={userId}
        />
      </Wrapper>
    </Container>
  );
};
export default ProfilePage;
