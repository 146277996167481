import { useMemo, useState } from "react";
import { TextLogo } from "../components/FormWrapper/FormWrapper";
import PasswordTextField from "../components/PasswordTextField/PasswordTextField";
import { Container, Wrapper } from "./ProfilePage";
import SButton from "../components/Button/SButton";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import UserService from "../services/UserService";
import Alert from "@mui/material/Alert";
import { User } from "../types/type";
import { Typography } from "@mui/material";
import { isValidPassword } from "../untils/untils";

const ActionButtonContainer = styled.div`
  display: flex;
  gap: 20px;
`;

interface PasswordChangePageProps {
  userInfo?: Pick<User, "_id" | "name">;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const PasswordChangePage = ({
  userInfo = { _id: "", name: "" },
  setOpen,
}: PasswordChangePageProps) => {
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState({});

  const navigate = useNavigate();
  const currentUser = useMemo(() => {
    return JSON.parse(localStorage.getItem("currentUser") || "{}");
  }, []);

  const isErrorForm = useMemo(() => {
    return currentUser.isAdmin && userInfo._id
      ? !newPassword
      : !oldPassword || !newPassword;
  }, [oldPassword, newPassword]);

  const handlePasswordChange = async () => {
    setIsLoading(true);
    try {
      await UserService.changePassword(
        userInfo._id ? userInfo._id : currentUser._id,
        { oldPassword, newPassword },
        currentUser.jwt
      ).then((res) => {
        setData(res);
        if (!userInfo._id) {
          UserService.logout(currentUser.jwt).then((res) => {
            localStorage.removeItem("currentUser");
            navigate("/login", {
              state: { status: "success-password-change" },
            });
          });
        }
      });
    } catch (err: any) {
      setError(err.response);
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setError(null);
        setData({});
      }, 2000);
    }
  };

  return (
    <Container>
      <Wrapper>
        <TextLogo>Đổi Mật Khẩu</TextLogo>
        {userInfo.name && (
          <Typography>Tên người dùng: {userInfo.name}</Typography>
        )}
        {Object.keys(data).length > 0 && (
          <Alert severity="success">Đổi mật khẩu thành công</Alert>
        )}
        {error?.data?.message === "Wrong password" && (
          <Alert severity="error">Mật khẩu cũ không chính xác</Alert>
        )}
        {!userInfo._id && (
          <PasswordTextField
            label="Mật khẩu cũ"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            isEmty={!oldPassword}
          />
        )}
        <PasswordTextField
          label="Mật khẩu mới"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          isEmty={!newPassword}
          isValid={isValidPassword(newPassword)}
        />

        <ActionButtonContainer>
          <SButton
            color="error"
            onClick={() => {
              userInfo._id ? setOpen?.(false) : navigate("/");
            }}
          >
            Hủy
          </SButton>
          <SButton
            onClick={() => {
              handlePasswordChange();
            }}
            disabled={isErrorForm}
          >
            Lưu
          </SButton>
        </ActionButtonContainer>
      </Wrapper>
    </Container>
  );
};

export default PasswordChangePage;
