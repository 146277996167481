import styled from "styled-components";
import SButton from "../../Button/SButton";
import {
  ContractState,
  Transaction,
  TransactionAdmin,
} from "../../../types/type";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TransactionFee from "./TransactionFee";
import ContractEditor from "./ContractEditor";
import TransactionState from "./TransactionState";

const Container = styled.div`
  height: 63%;
  display: flex;
  flex-direction: column;
`;
const TransactionHeaderWrapper = styled.div`
  padding: 0 10px;
  height: 40px;
  flex-shrink: 0;
  border: 1px solid #ccc;
  border-left: 0;
  border-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #eef0f1;
`;
const TransactionFeeAndStateWrapper = styled.div`
  height: 108px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ccc;
`;
export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface AgreementContentProps {
  isSuccess: boolean;
  transaction: Transaction;
  transactionAdmin: TransactionAdmin;
  contractState: ContractState;
  onUpdateCachedData: (key: keyof Transaction, data: any) => void;
}

const AgreementContent = ({
  isSuccess,
  transaction,
  transactionAdmin,
  contractState,
  onUpdateCachedData,
}: AgreementContentProps) => {
  const navigate = useNavigate();

  return (
    <Container>
      <TransactionHeaderWrapper>
        <SButton
          size="small"
          color="secondary"
          variant="text"
          onClick={(e) => navigate("/")}
          sx={{ position: "absolute", left: "10px" }}
        >
          <ArrowBackIcon sx={{ color: "#00524e", fontSize: "22px" }} />
        </SButton>
        <div style={{ fontWeight: 600 }}>{transaction.name.toUpperCase()}</div>
      </TransactionHeaderWrapper>
      <ContractEditor
        isSuccess={isSuccess}
        transactionId={transaction._id}
        contractContent={transaction.contract}
        amountOfMoney={transaction.amountOfMoney}
        depositRate={transaction.depositRate}
        contractState={contractState}
        transactionAdmin={transactionAdmin}
        onUpdateCachedData={onUpdateCachedData}
      />
      <TransactionFeeAndStateWrapper>
        <TransactionFee
          isSuccess={isSuccess}
          amountOfMoney={transaction.amountOfMoney}
          depositRate={transaction.depositRate}
          transferCode={transaction.transferCode}
          transactionFees={transaction.transactionFees}
          onUpdateCachedData={onUpdateCachedData}
        />
        <TransactionState
          transactionId={transaction._id}
          transactionState={transaction.transactionState}
          transactionAdmin={transactionAdmin}
        />
      </TransactionFeeAndStateWrapper>
    </Container>
  );
};

export default AgreementContent;
