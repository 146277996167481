import axios from "axios";
import { BASE_URL } from "./config";
import { FeeRate } from "../types/type";
import qs from "qs";

export default {
  getFeeAmount: async (
    params: { amountOfMoney: string | number },
    token: string
  ) => {
    const res = await axios.get(`${BASE_URL}/settings/feeAmount`, {
      headers: { Authorization: `Bearer ${token}` },
      params: params,
    });
    return res.data;
  },
  getFeeRateList: async (token: string) => {
    const res = await axios.get<FeeRate[]>(`${BASE_URL}/settings/feeRates`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  },
  deleteFeeRate: async (feeRateId: string, token: string) => {
    const res = await axios.delete<FeeRate[]>(
      `${BASE_URL}/settings/feeRates/${feeRateId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res.data;
  },
  updateFeeRate: async (
    feeRateId: string,
    feeRate: Omit<FeeRate, "_id">,
    token: string
  ) => {
    const res = await axios.put<FeeRate[]>(
      `${BASE_URL}/settings/feeRates/${feeRateId}`,
      qs.stringify(feeRate),
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res.data;
  },
  addFeeRate: async (feeRate: Omit<FeeRate, "_id">, token: string) => {
    const res = await axios.post<FeeRate[]>(
      `${BASE_URL}/settings/feeRates`,
      qs.stringify(feeRate),
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res.data;
  },
};
