import { styled as styledMUI } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SButton from "../Button/SButton";
import { SvgIconProps, Tooltip } from "@mui/material";
import { ReactElement, useMemo } from "react";

const StyledTableCell = styledMUI(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: "#00524E",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styledMUI(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface STableProps {
  tableHeadData: Array<{
    text: string;
    keyValue?: string;
    roles: { isAdmin?: boolean; isUser?: boolean };
  }>;
  tableBodyData: Array<any>;
  actionCellData: Array<{
    actionName: string;
    icon: ReactElement<SvgIconProps>;
    buttonColor: any;
    onClick: (selectedData: any, event?: React.MouseEvent<HTMLElement>) => void;
    roles: { isAdmin?: boolean; isUser?: boolean };
  }>;
}

const STable = ({
  tableHeadData,
  tableBodyData,
  actionCellData,
}: STableProps) => {
  const currentUser = useMemo(() => {
    return JSON.parse(localStorage.getItem("currentUser") || "{}");
  }, []);

  const actionCell = (selectedData: any) => {
    return (
      <div style={{ display: "flex", gap: "10px" }}>
        {actionCellData.map((data) => {
          const cell = (
            <Tooltip
              title={data.actionName}
              arrow
              key={`${selectedData._id}${data.actionName}`}
            >
              {/* add div tag to fix tooltip not working */}
              <div>
                <SButton
                  size="medium"
                  color={data.buttonColor}
                  onClick={(e) => data.onClick(selectedData, e)}
                >
                  {data.icon}
                </SButton>
              </div>
            </Tooltip>
          );
          if (currentUser.isAdmin && data.roles.isAdmin) {
            return cell;
          }
          if (!currentUser.isAdmin && data.roles.isUser) {
            return cell;
          }
          return <></>;
        })}
      </div>
    );
  };

  return (
    <TableContainer component={Paper} sx={{ flex: "1" }}>
      <Table aria-label="customized table" stickyHeader>
        <TableHead>
          <TableRow>
            {tableHeadData.map((data) => {
              const cell = (
                <StyledTableCell key={data.text}>{data.text}</StyledTableCell>
              );
              if (currentUser.isAdmin && data.roles.isAdmin) {
                return cell;
              }
              if (!currentUser.isAdmin && data.roles.isUser) {
                return cell;
              }
              return <></>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableBodyData.map((data) => (
            <StyledTableRow key={data._id}>
              {tableHeadData.map((d) => {
                const cell = (
                  <StyledTableCell key={`${data._id}${d.text}`}>
                    {!d.keyValue //is action column
                      ? actionCell(data)
                      : //normal
                        data[d.keyValue]}
                  </StyledTableCell>
                );
                if (currentUser.isAdmin && d.roles.isAdmin) {
                  return cell;
                }
                if (!currentUser.isAdmin && d.roles.isUser) {
                  return cell;
                }
                return <></>;
              })}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default STable;
