import { useEffect, useMemo, useState } from "react";
import UserService from "../../../services/UserService";
import SFormDialog from "../../FormDialog/SFormDialog";
import STextField from "../../TextField/STextField";
import {
  BankAccountType,
  Step,
  UpdateBankAccountFormType,
} from "../../../types/type";
import SStepper from "../../Stepper/SStepper";
import BankAccountInfoStep from "./BankAccountInfoStep";
import { toast } from "react-toastify";
import { alertStatus } from "../../../untils/untils";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface UpdateBankAccountProps {
  bankAccount: BankAccountType;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readOnly?: boolean;
}

const UpdateBankAccount = ({
  bankAccount,
  open,
  setOpen,
}: UpdateBankAccountProps) => {
  const queryClient = useQueryClient();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [updateBankAccountFormData, setUpdateBankAccountFormData] =
    useState<UpdateBankAccountFormType>({
      ...bankAccount,
      otp: "",
    });

  useEffect(() => {
    setUpdateBankAccountFormData({
      ...bankAccount,
      otp: "",
    });
  }, [bankAccount]);

  const currentUser = useMemo(() => {
    return JSON.parse(localStorage.getItem("currentUser") || "{}");
  }, []);

  const isErrorForm = useMemo(() => {
    return (
      updateBankAccountFormData.accountNumber === "" ||
      updateBankAccountFormData.accountOwner === "" ||
      updateBankAccountFormData.bankName === "" ||
      (bankAccount.accountNumber === updateBankAccountFormData.accountNumber &&
        bankAccount.accountOwner === updateBankAccountFormData.accountOwner &&
        bankAccount.bankName === updateBankAccountFormData.bankName)
    );
  }, [bankAccount, updateBankAccountFormData]);

  const sendOTPMutation = useMutation({
    mutationFn: (email: string) => {
      return UserService.sendOTP(email, "general");
    },
    onSuccess: () => {
      toast.success(
        `Mã OTP đã được gửi đến ${currentUser.email}. Vui lòng kiểm tra Hộp thư đến để lấy mã OTP và nhập vào ô "Mã OTP"`
      );
      setActiveStep(activeStep + 1);
    },
    onError: (error: any) => {
      const errorMessage = error.response.data.message;
      alertStatus(errorMessage);
      if (errorMessage === "This account does not exist") {
        setActiveStep(0);
        return;
      }
      setActiveStep(activeStep + 1);
    },
  });

  const handleSendOTP = () => {
    sendOTPMutation.mutate(currentUser.email);
  };

  const handleUpdateBankAccount = async () => {
    try {
      await UserService.updateBankAccount({
        _id: currentUser._id,
        jwt: currentUser.jwt,
        updateBankAccountFormData,
      }).then((res) => {
        queryClient.invalidateQueries({
          queryKey: ["profile", currentUser._id],
        });
        toast.success("Tài khoản ngân đã được cập nhật thành công.");
      });
    } catch (error: any) {
      alertStatus(error.response.data.message);
    } finally {
    }
  };

  const updateBankAccountSteps: Step[] = [
    {
      label: (
        <>
          Thông tin tài khoản
          <br />
          ngân hàng
        </>
      ),
      contentComponent: (
        <BankAccountInfoStep
          value={updateBankAccountFormData}
          setValue={setUpdateBankAccountFormData}
        />
      ),
      enabledNext: !isErrorForm,
      nextAction: handleSendOTP,
    },
    {
      label: "OTP",
      contentComponent: (
        <STextField
          label="Mã OTP"
          fullWidth
          value={updateBankAccountFormData.otp}
          onChange={(e) =>
            setUpdateBankAccountFormData({
              ...updateBankAccountFormData,
              otp: e.target.value,
            })
          }
          isEmty={updateBankAccountFormData.otp === ""}
          sx={{ marginTop: "10px" }}
        />
      ),
      enabledNext: !(updateBankAccountFormData.otp === ""),
    },
  ];

  return (
    <SFormDialog
      title="Cập Nhật Tài Khoản Ngân Hàng"
      isError={isErrorForm || updateBankAccountFormData.otp === ""}
      open={open}
      close={() => setOpen(false)}
      onSave={handleUpdateBankAccount}
    >
      <SStepper
        steps={updateBankAccountSteps}
        activeStep={activeStep}
        handleNext={() => setActiveStep(activeStep + 1)}
        handleBack={() => setActiveStep(activeStep - 1)}
      />
    </SFormDialog>
  );
};
export default UpdateBankAccount;
