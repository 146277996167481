import PasswordTextField from "../PasswordTextField/PasswordTextField";
import STextField from "../TextField/STextField";
import { InputWrapper } from "../Register/RequiredInput";
import { User } from "../../types/type";
import { isValidPassword } from "../../untils/untils";

interface ForgotPasswordStepInput {
  forgotPasswordForm: Pick<User, "email"> & {
    otp: number | string;
    newPassword: string;
  };
  setForgotPasswordForm: React.Dispatch<
    React.SetStateAction<
      Pick<User, "email"> & { otp: number | string; newPassword: string }
    >
  >;
}

export default function RequiredInput({
  forgotPasswordForm,
  setForgotPasswordForm,
}: ForgotPasswordStepInput) {
  return (
    <InputWrapper>
      <STextField
        required
        label="Mã OTP"
        value={forgotPasswordForm.otp}
        onChange={(e) =>
          setForgotPasswordForm({ ...forgotPasswordForm, otp: e.target.value })
        }
        isEmty={forgotPasswordForm.otp === ""}
      />
      <PasswordTextField
        required
        label="Mật khẩu mới"
        value={forgotPasswordForm.newPassword}
        onChange={(e) =>
          setForgotPasswordForm({
            ...forgotPasswordForm,
            newPassword: e.target.value,
          })
        }
        isEmty={forgotPasswordForm.newPassword === ""}
        isValid={isValidPassword(forgotPasswordForm.newPassword)}
      />
    </InputWrapper>
  );
}
