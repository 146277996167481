import { useEffect, useMemo, useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import styled from "styled-components";

const HelperText = styled.small`
  font-size: 12px;
  color: #d32f2f;
  margin-top: 3px;
  margin-left: 14px;
  letter-spacing: 0.03333em;
`;

type SOutlinedInputProps = OutlinedInputProps & {
  isEmty?: boolean;
  isValid?: boolean;
};

const PasswordTextField = ({
  isEmty,
  isValid = true,
  ...props
}: SOutlinedInputProps) => {
  const [isInputChanged, setIsInputChanged] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (props.value) {
      setIsInputChanged(true);
    }
  }, [props.value]);

  const helperText = useMemo(() => {
    return !isInputChanged
      ? ""
      : isEmty
      ? "Trường này là bắt buộc."
      : !isValid
      ? "Mật khẩu phải có tối thiểu 8 ký tự. Bao gồm chữ và số, chữ thường và chữ hoa."
      : "";
  }, [isInputChanged, isEmty, isValid]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel
        required={props.required}
        htmlFor="outlined-adornment-password"
        error={(isEmty || !isValid) && isInputChanged ? true : false}
      >
        {props.label}
      </InputLabel>
      <OutlinedInput
        {...props}
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        error={(isEmty || !isValid) && isInputChanged ? true : false}
      />
      <HelperText>{helperText}</HelperText>
    </FormControl>
  );
};

export default PasswordTextField;
