import styled from "styled-components";
import { TextLogo } from "../components/FormWrapper/FormWrapper";
import { useMemo, useState } from "react";
import UserService from "../services/UserService";
import Tooltip from "@mui/material/Tooltip";
import SButton from "../components/Button/SButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { INITIAL_PROFILE } from "../untils/constants";
import { useQuery } from "@tanstack/react-query";
import UpdateBankAccount from "../components/BankAccount/Update Bank Account/UpdateBankAccount";
import BankAccountInfo from "../components/BankAccount/BankAccountInfo";
import { Container, Wrapper } from "./ProfilePage";

const BankAccountPage = () => {
  const [openUpdateProfile, setOpenUpdateProfile] = useState(false);
  const navigate = useNavigate();

  const currentUser = useMemo(() => {
    return JSON.parse(localStorage.getItem("currentUser") || "{}");
  }, []);

  const { data: profile } = useQuery({
    queryKey: ["profile", currentUser._id],
    queryFn: () => UserService.getProfile(currentUser._id, currentUser.jwt),
  });

  return (
    <Container>
      <Wrapper>
        <TextLogo>Thông Tin Tài Khoản Ngân Hàng</TextLogo>
        <BankAccountInfo
          data={profile?.bankAccount || INITIAL_PROFILE.bankAccount}
        />
        {/* update bank account */}
        <div style={{ display: "flex", gap: "20px" }}>
          <Tooltip title="Quay lại">
            <div>
              <SButton color="secondary" onClick={() => navigate("/")}>
                <ArrowBackIcon fontSize="small" />
              </SButton>
            </div>
          </Tooltip>

          <Tooltip title="Cập nhật">
            <div>
              <SButton onClick={() => setOpenUpdateProfile(true)} color="info">
                <EditOutlinedIcon fontSize="small" />
              </SButton>
            </div>
          </Tooltip>
        </div>
        <UpdateBankAccount
          bankAccount={profile?.bankAccount || INITIAL_PROFILE.bankAccount}
          open={openUpdateProfile}
          setOpen={setOpenUpdateProfile}
        />
      </Wrapper>
    </Container>
  );
};
export default BankAccountPage;
