import styled from "styled-components";
import THTopBar from "../components/TransactionHistory/THTopBar";
import TransactionHistory from "../components/TransactionHistory/TransactionHistory";

export const Container = styled.div`
  height: 100vh;
  overflow: hidden;
`;

const TransactionHistoryPage = () => {
  return (
    <Container>
      <THTopBar />
      <TransactionHistory />
    </Container>
  );
};

export default TransactionHistoryPage;
