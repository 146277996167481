import styled, { css } from "styled-components";
import Message from "./Message";
import Avatar from "@mui/material/Avatar";
import { useMemo } from "react";
import { MessageEnum } from "../../../types/type";
import { AgreementChatProps } from "./AgreementChat";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";

const Container = styled.div<{ $isCenter: boolean }>`
  flex: 1;
  padding: 8px;
  overflow-y: overlay;
  overflow-x: hidden;
  background-color: #eef0f1;
  ${(props) =>
    props.$isCenter &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`;

const MessageContainer = styled.div<{ $isLeft: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.$isLeft ? "row" : "row-reverse")};
  gap: 5px;
`;

const Messages = ({
  conversations,
  messagesEnd,
}: Omit<AgreementChatProps, "transactionId">) => {
  const isEmptyConversations = useMemo(() => {
    return !conversations.length;
  }, [conversations]);

  return (
    <Container $isCenter={isEmptyConversations}>
      {isEmptyConversations && (
        <ForumOutlinedIcon sx={{ fontSize: "60px", color: "#9E9E9E" }} />
      )}
      {conversations.map((m, i) => {
        const isLeft = m.messageType === MessageEnum.MessageA;

        const isFirstPoint = m.senderID !== conversations[i - 1]?.senderID;

        return (
          <MessageContainer $isLeft={isLeft} key={i}>
            {isFirstPoint && <Avatar src="" />}
            <Message message={m} isLeft={isLeft} isFirstPoint={isFirstPoint} />
          </MessageContainer>
        );
      })}
      <div ref={messagesEnd}></div>
    </Container>
  );
};

export default Messages;
