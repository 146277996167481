import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import SMenu from "../Menu/SMenu";
import { useMemo, useState } from "react";
import { MenuItemAction, Profile } from "../../types/type";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

interface SearchResultListProps {
  data: Array<Profile>;
  onSlectedItem?: (value: any) => void;
  fullHeight?: boolean;
  fullSubInfo?: boolean;
  highlightFirstItem?: boolean;
  hasBorder?: boolean;
  hasSecondaryAction?: boolean;
  menuItemActions?: Array<MenuItemAction>;
  showMenuItemActions?: boolean;
}

const SearchResultList = ({
  data = [],
  onSlectedItem,
  fullHeight,
  fullSubInfo,
  highlightFirstItem,
  hasBorder,
  hasSecondaryAction,
  menuItemActions = [],
  showMenuItemActions,
}: SearchResultListProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  //open or close
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <List
      dense
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        maxHeight: fullHeight ? "100%" : "250px",
        overflowY: "auto",
        border: hasBorder && data?.length ? "1px solid #ccc" : "none",
        "&.MuiList-root": {
          paddingTop: 0,
        },
      }}
    >
      {data.map((value, i) => {
        return (
          <ListItem
            key={value._id}
            disablePadding
            onClick={() => {
              onSlectedItem?.(hasBorder ? value._id : value);
            }}
            secondaryAction={
              hasSecondaryAction && showMenuItemActions && i !== 0 ? (
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(e) => {
                    handleMenu(e);
                  }}
                >
                  <MoreHorizOutlinedIcon />
                </IconButton>
              ) : (
                <></>
              )
            }
          >
            <ListItemButton>
              <ListItemAvatar>
                <Avatar src="" />
              </ListItemAvatar>
              <ListItemText
                primary={
                  i === 0 && highlightFirstItem
                    ? `${value.name} (Admin)`
                    : `${value.name}`
                }
                secondary={
                  fullSubInfo
                    ? `${value.email} (${value.phone})`
                    : `${value.phone}`
                }
              />
            </ListItemButton>
            {/* Dialog */}
            <SMenu
              anchorEl={anchorEl}
              onClose={handleClose}
              menuItemActions={menuItemActions}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default SearchResultList;
