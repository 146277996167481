import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import SButton from "../Button/SButton";
import { Tooltip } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckIcon from "@mui/icons-material/Check";

interface SFormDialogProps {
  children: React.ReactElement;
  title: string;
  saveName?: string;
  cancelName?: string;
  isError?: boolean;
  open: boolean;
  close: () => void;
  onSave?(
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void | Promise<void>;
  onCancel?(): void | Promise<void>;
  noSaveButton?: boolean;
  hasDialogActions?: boolean;
}

export default function SFormDialog({
  children,
  saveName = "Lưu",
  cancelName = "Huỷ",
  isError = false,
  hasDialogActions = true,
  ...props
}: SFormDialogProps) {
  const handleClose = () => {
    props.close();
    props.onCancel?.();
  };

  return (
    <Dialog
      sx={{
        "& .MuiList-root": { paddingBottom: "0px", paddingTop: "0px" },
      }}
      open={props.open}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle sx={{ marginBottom: "0px", textAlign: "center" }}>
        {props.title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {hasDialogActions && (
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            paddingBottom: "16px",
          }}
        >
          <Tooltip title={cancelName} arrow>
            <div>
              <SButton color="error" onClick={(e) => handleClose()}>
                <CloseOutlinedIcon fontSize="small" />
              </SButton>
            </div>
          </Tooltip>
          {!props.noSaveButton && (
            <Tooltip title={saveName} arrow>
              <div>
                <SButton
                  onClick={(e) => {
                    props.close();
                    props.onSave?.(e);
                  }}
                  disabled={isError}
                >
                  <CheckIcon fontSize="small" />
                </SButton>
              </div>
            </Tooltip>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}
