import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import UserService from "../../services/UserService";
import SFormDialog from "../FormDialog/SFormDialog";
import STextField from "../TextField/STextField";
import { Profile } from "../../types/type";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { INITIAL_PROFILE } from "../../untils/constants";
import { toast } from "react-toastify";
import { alertStatus } from "../../untils/untils";
import { useQueryClient } from "@tanstack/react-query";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TextFieldContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

interface UpdateProfileProps {
  profile: Profile;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readOnly?: boolean;
  userId?: string;
}

const UpdateProfile = ({
  profile,
  open,
  setOpen,
  readOnly = false,
  userId,
}: UpdateProfileProps) => {
  const queryClient = useQueryClient();
  const [profileFormData, setProfileFormData] =
    useState<Profile>(INITIAL_PROFILE);

  const currentUser = useMemo(() => {
    return JSON.parse(localStorage.getItem("currentUser") || "{}");
  }, []);

  useEffect(() => {
    setProfileFormData(profile);
  }, [profile]);

  const isErrorForm = useMemo(() => {
    return (
      !profileFormData?.name ||
      !profileFormData?.phone ||
      !profileFormData?.email
    );
  }, [profileFormData]);

  const updateProfile = async () => {
    try {
      const userID = userId ? userId : currentUser._id;
      await UserService.updateProfile(
        userID,
        profileFormData,
        currentUser.jwt
      ).then((res) => {
        queryClient.invalidateQueries({
          queryKey: ["profile", userID],
        });
        //Xem xét nếu có lỗi hoặc vấn đề performance truyền thêm key vào queryKey bên dưới
        queryClient.invalidateQueries({
          queryKey: ["users"],
        });
        toast.success("Hồ sơ đã được cập nhật thành công.");
      });
    } catch (error: any) {
      alertStatus(error.response.data.message);
    } finally {
    }
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProfileFormData({
      ...profileFormData,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <SFormDialog
      title="Cập Nhật Hồ Sơ"
      isError={isErrorForm}
      open={open}
      close={() => setOpen(false)}
      onSave={updateProfile}
    >
      <Container>
        <TextFieldContainer>
          <STextField
            label="Họ và tên"
            fullWidth
            InputProps={{ readOnly: readOnly }}
            value={profileFormData?.name || ""}
            onChange={(e) =>
              setProfileFormData({ ...profileFormData, name: e.target.value })
            }
            isEmty={!profileFormData?.name}
            sx={{ marginTop: "10px" }}
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <STextField
            label="Email"
            fullWidth
            InputProps={{ readOnly: true }}
            value={profileFormData?.email || ""}
            onChange={(e) =>
              setProfileFormData({
                ...profileFormData,
                email: e.target.value,
              })
            }
            isEmty={!profileFormData?.email}
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <STextField
            label="Số điện thoại"
            fullWidth
            InputProps={{ readOnly: true }}
            value={profileFormData?.phone || ""}
            onChange={(e) =>
              setProfileFormData({
                ...profileFormData,
                phone: e.target.value,
              })
            }
            isEmty={!profileFormData?.phone}
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <STextField
            label="Địa chỉ"
            fullWidth
            InputProps={{ readOnly: readOnly }}
            value={profileFormData?.address || ""}
            onChange={(e) =>
              setProfileFormData({
                ...profileFormData,
                address: e.target.value,
              })
            }
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <STextField
            label="Tên công ty"
            fullWidth
            InputProps={{ readOnly: readOnly }}
            value={profileFormData?.companyName || ""}
            onChange={(e) =>
              setProfileFormData({
                ...profileFormData,
                companyName: e.target.value,
              })
            }
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <STextField
            label="Chức vụ"
            fullWidth
            InputProps={{ readOnly: readOnly }}
            value={profileFormData?.position || ""}
            onChange={(e) =>
              setProfileFormData({
                ...profileFormData,
                position: e.target.value,
              })
            }
          />
        </TextFieldContainer>
        {currentUser.isAdmin && (
          <div>
            <FormControlLabel
              sx={{ marginRight: "50px" }}
              control={
                <Checkbox
                  checked={profileFormData.isAdmin}
                  onChange={(e) => handleChangeCheckbox(e)}
                  name="isAdmin"
                />
              }
              label="Admin"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={profileFormData.isActive}
                  onChange={(e) => handleChangeCheckbox(e)}
                  name="isActive"
                />
              }
              label="Đang hoạt động"
            />
          </div>
        )}
      </Container>
    </SFormDialog>
  );
};
export default UpdateProfile;
