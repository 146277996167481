import STextField from "../TextField/STextField";
import { InputWrapper } from "./RequiredInput";
import { RegisterStepsProps } from "../../pages/RegisterPage";

export default function OptionalInput({ newUser, setNewUser }: RegisterStepsProps) {
  return (
    <InputWrapper>
      <STextField
        label="Địa chỉ"
        value={newUser.address}
        onChange={(e) => setNewUser({ ...newUser, address: e.target.value })}
      />
      <STextField
        label="Tên công ty"
        value={newUser.companyName}
        onChange={(e) =>
          setNewUser({ ...newUser, companyName: e.target.value })
        }
      />
      <STextField
        label="Chức vụ"
        value={newUser.position}
        onChange={(e) => setNewUser({ ...newUser, position: e.target.value })}
      />
    </InputWrapper>
  );
}
