import {
  ContractStateEnum,
  FeeRate,
  Message,
  MessageEnum,
  Profile,
  Transaction,
  TransactionStateEnum,
  TransactionStateVieEnum,
} from "../types/type";

export const TH_TABLE_HEAD = [
  {
    text: "Thời Gian Tạo",
    keyValue: "createdAt",
    roles: { isAdmin: true, isUser: true },
  },
  { text: "Mã Giao Dịch", keyValue: "transferCode", roles: { isAdmin: true } },
  {
    text: "Tên Giao Dịch",
    keyValue: "name",
    roles: { isAdmin: true, isUser: true },
  },
  {
    text: "Số Tiền Giao Dịch",
    keyValue: "amountOfMoney",
    roles: { isAdmin: true, isUser: true },
  },
  {
    text: "Trạng Thái Giao Dịch",
    keyValue: "transactionState",
    roles: { isAdmin: true, isUser: true },
  },
  { text: "Hành Động", roles: { isAdmin: true, isUser: true } },
];

export const UM_TABLE_HEAD = [
  {
    text: "Thời Gian Tạo",
    keyValue: "createdAt",
    roles: { isAdmin: true },
  },
  {
    text: "Tên",
    keyValue: "name",
    roles: { isAdmin: true },
  },
  {
    text: "Email",
    keyValue: "email",
    roles: { isAdmin: true },
  },
  {
    text: "Số Điện Thoại",
    keyValue: "phone",
    roles: { isAdmin: true },
  },
  {
    text: "Chức vụ",
    keyValue: "position",
    roles: { isAdmin: true },
  },
  { text: "Hành Động", roles: { isAdmin: true } },
];

export const FRM_TABLE_HEAD = [
  {
    text: "Tổng Tiền Từ (VNĐ)",
    keyValue: "amountFrom",
    roles: { isAdmin: true },
  },
  {
    text: "Tổng Tiền Đến (VNĐ)",
    keyValue: "amountTo",
    roles: { isAdmin: true },
  },
  {
    text: "Tỉ Lệ Phí (%)",
    keyValue: "feeRate",
    roles: { isAdmin: true },
  },
  { text: "Hành Động", roles: { isAdmin: true } },
];

export const INITIAL_PROFILE: Profile = {
  _id: "",
  address: "",
  companyName: "",
  email: "",
  isActive: false,
  isAdmin: false,
  name: "",
  phone: "",
  position: "",
  createdAt: "",
  updatedAt: "",
  bankAccount: {
    accountNumber: "",
    accountOwner: "",
    bankName: "",
  },
};

export const INITIAL_MESSAGE: Message = {
  _id: "",
  createdAt: "",
  message: "",
  messageType: MessageEnum.MessageA,
  senderID: "",
  senderName: "",
};

export const INITIAL_TRANSACTION: Transaction = {
  _id: "",
  adminA: INITIAL_PROFILE,
  adminB: INITIAL_PROFILE,
  amountOfMoney: 0,
  contract: "",
  contractState: ContractStateEnum.Drafting,
  conversations: [],
  createdAt: "",
  creator: "",
  depositRate: 0,
  membersA: [],
  membersB: [],
  name: "",
  transactionFees: 0,
  transactionState: TransactionStateEnum.Created,
  transferCode: "",
  updatedAt: "",
};

export const INITIAL_FEE_RATE: FeeRate = {
  _id: "",
  amountFrom: "",
  amountTo: "",
  feeRate: "",
};

export const TRANSACTION_STATE_STEPS = [
  TransactionStateVieEnum.Deposited,
  TransactionStateVieEnum.Sent,
  TransactionStateVieEnum.Received,
  TransactionStateVieEnum.Accomplished,
];
