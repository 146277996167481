import styled from "styled-components";
import { AgreementChatProps } from "./AgreementChat";
import TransactionService from "../../../services/TransactionService";
import { useMemo, useState } from "react";
import STextField from "../../TextField/STextField";
import SendIcon from "@mui/icons-material/Send";
import { Center } from "../AgreementContent/AgreementContent";
import { useMutation } from "@tanstack/react-query";
import { alertStatus } from "../../../untils/untils";

const Container = styled.div`
  padding: 0 10px 0 0;
  height: fit-content;
  display: flex;
  align-items: center;
  border-top: 1px solid #ccc;
`;

const SendButton = styled(Center)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  &:hover {
    background-color: #f0f2f5;
    cursor: pointer;
  }
`;

const InputBox = ({
  transactionId,
}: Omit<AgreementChatProps, "messagesEnd" | "conversations">) => {
  const [textMessage, setTextMessage] = useState<string>("");

  const currentUser = useMemo(() => {
    return JSON.parse(localStorage.getItem("currentUser") || "{}");
  }, []);

  const messageMutation = useMutation({
    mutationFn: (message: string) => {
      return TransactionService.sendMessage(
        message,
        transactionId,
        currentUser.jwt
      );
    },
  });

  const handleSendMessage = async () => {
    if (textMessage) {
      setTextMessage("");
      messageMutation.mutate(textMessage, {
        onError: (error: any) => {
          const errorMessage = error.response.data.message;
          alertStatus(errorMessage);
        },
      });
    }
  };

  const onEnterPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && e.shiftKey === false) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <Container>
      <STextField
        placeholder="Aa..."
        size="small"
        multiline
        maxRows={4}
        // disableBorder
        fullWidth
        value={textMessage}
        onKeyDown={(e) => onEnterPress(e)}
        onChange={(e) => setTextMessage(e.target.value)}
        sx={{
          padding: "5px",
          "& .MuiOutlinedInput-root": {
            borderRadius: "20px",
            backgroundColor: "#ffffff",
          },
        }}
      />
      <SendButton onClick={() => handleSendMessage()}>
        <SendIcon sx={{ color: "#0095C5", fontSize: "20px" }} />
      </SendButton>
    </Container>
  );
};

export default InputBox;
