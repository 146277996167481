import {
  ActionContainer,
  Container,
  Title,
} from "../TransactionHistory/TransactionHistory";
import STable from "../Table/STable";
import SFormDialog from "../FormDialog/SFormDialog";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import SettingsService from "../../services/SettingsService";
import { FRM_TABLE_HEAD, INITIAL_FEE_RATE } from "../../untils/constants";
import Tooltip from "@mui/material/Tooltip";
import SButton from "../Button/SButton";
import AddIcon from "@mui/icons-material/Add";
import CreateOrEditFeeRate from "./CreateOrEditFeeRate";
import { FeeRate } from "../../types/type";
import { toast } from "react-toastify";
import { convertNumberToVND, keepNumberOfString } from "../../untils/untils";

export default function FeeRateManagement() {
  const queryClient = useQueryClient();
  const [openFeeRateAddOrEditDialog, setOpenFeeRateAddOrEditDialog] =
    useState<boolean>(false);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    useState<boolean>(false);
  const [seletedFeeRate, setSeletedFeeRate] =
    useState<FeeRate>(INITIAL_FEE_RATE);

  const currentUser = useMemo(() => {
    return JSON.parse(localStorage.getItem("currentUser") || "{}");
  }, []);

  const { data: feeRateList } = useQuery({
    queryKey: ["feeRateList"],
    queryFn: () => SettingsService.getFeeRateList(currentUser.jwt),
  });

  const addFeeRateMutation = useMutation({
    mutationFn: () => {
      const { _id, ...seletedFeeRateRest } = seletedFeeRate;
      return SettingsService.addFeeRate(seletedFeeRateRest, currentUser.jwt);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["feeRateList"], data);
      toast.success("Thêm thành công");
    },
  });

  const updateFeeRateMutation = useMutation({
    mutationFn: () => {
      const { _id, ...seletedFeeRateRest } = seletedFeeRate;
      return SettingsService.updateFeeRate(
        seletedFeeRate._id,
        seletedFeeRateRest,
        currentUser.jwt
      );
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["feeRateList"], data);
      toast.success("Cập nhật thành công");
    },
  });

  const deleteFeeRateMutation = useMutation({
    mutationFn: () => {
      return SettingsService.deleteFeeRate(seletedFeeRate._id, currentUser.jwt);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["feeRateList"], data);
      toast.success("Xoá thành công");
    },
  });

  const handleAddOrEditFeeRate = () => {
    if (seletedFeeRate._id) {
      updateFeeRateMutation.mutate();
    } else {
      addFeeRateMutation.mutate();
    }
  };

  const handleDeteteFeeRate = () => {
    deleteFeeRateMutation.mutate();
  };

  //handle click
  const handleClickEdit = (feeRate: FeeRate) => {
    setSeletedFeeRate({
      ...feeRate,
      amountFrom: keepNumberOfString(feeRate.amountFrom),
      amountTo: keepNumberOfString(feeRate.amountTo),
    });
    setOpenFeeRateAddOrEditDialog(true);
  };
  const handleClickDelete = (feeRate: FeeRate) => {
    setSeletedFeeRate(feeRate);
    setOpenDeleteConfirmationDialog(true);
  };

  const ACTION_CELL_DATA = [
    {
      actionName: "Chỉnh sửa",
      icon: <EditOutlinedIcon fontSize="small" />,
      buttonColor: "info",
      onClick: handleClickEdit,
      roles: { isAdmin: true },
    },
    {
      actionName: "Xoá",
      icon: <DeleteOutlineIcon fontSize="small" />,
      buttonColor: "error",
      onClick: handleClickDelete,
      roles: { isAdmin: true },
    },
  ];

  const tableBodyData = useMemo(() => {
    return (feeRateList || []).map((feeRate) => {
      return {
        ...feeRate,
        amountFrom: convertNumberToVND(feeRate.amountFrom),
        amountTo: convertNumberToVND(feeRate.amountTo),
      };
    });
  }, [feeRateList]);

  return (
    <Container>
      <Title>Quản Lý Tỉ Lệ Phí</Title>
      <ActionContainer style={{ justifyContent: "flex-end" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Tooltip title="Tạo tỉ lệ phí" arrow>
            <div>
              <SButton
                onClick={() => {
                  setSeletedFeeRate(INITIAL_FEE_RATE);
                  setOpenFeeRateAddOrEditDialog(true);
                }}
                color="info"
              >
                <AddIcon />
              </SButton>
            </div>
          </Tooltip>
        </div>
      </ActionContainer>

      {/* Table */}
      <STable
        tableHeadData={FRM_TABLE_HEAD}
        tableBodyData={tableBodyData}
        actionCellData={ACTION_CELL_DATA}
      />
      {/* Add or Edit Fee Rate Form*/}
      <CreateOrEditFeeRate
        open={openFeeRateAddOrEditDialog}
        setOpen={setOpenFeeRateAddOrEditDialog}
        feeRate={seletedFeeRate}
        setFeeRate={setSeletedFeeRate}
        onAddOrEditFeeRate={handleAddOrEditFeeRate}
      />
      {/* Confirm delete fee rate */}
      <SFormDialog
        title={`Bạn Có Chắc Chắn Muốn Xóa Tỉ Lệ Phí Này?`}
        saveName="Xoá"
        isError={false}
        open={openDeleteConfirmationDialog}
        close={() => setOpenDeleteConfirmationDialog(false)}
        onSave={() => handleDeteteFeeRate()}
      >
        <></>
      </SFormDialog>
    </Container>
  );
}
