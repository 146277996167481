import styled from "styled-components";
import STextField from "../../TextField/STextField";
import InputAdornment from "@mui/material/InputAdornment";
import { toast } from "react-toastify";
import SButton from "../../Button/SButton";
import SFormDialog from "../../FormDialog/SFormDialog";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import { Transaction } from "../../../types/type";
import { useEffect, useMemo, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@mui/material";
import transactionFeesInfo from "../../../assets/transactionFeesInfo.png";
import ModalImage from "react-modal-image";
import { useQuery } from "@tanstack/react-query";
import SettingsService from "../../../services/SettingsService";
import { useDebounce } from "../../../hooks/useDebounce";
import {
  convertNumberToVND,
  convertNumberWithDot,
} from "../../../untils/untils";

const Container = styled.div`
  height: 40px;
  display: flex;
  border-bottom: 1px solid #ccc;
  .MuiTextField-root {
    flex: 1;
  }
`;

export const TransactionFeesInfo = styled.img`
  width: 45%;
`;

interface TransactionFeeProps {
  isSuccess: boolean;
  amountOfMoney: string | number;
  depositRate: string | number;
  transferCode: string;
  transactionFees: number | string;
  onUpdateCachedData: (key: keyof Transaction, data: any) => void;
}

export default function TransactionFee({
  isSuccess,
  amountOfMoney,
  depositRate,
  transferCode,
  transactionFees,
  onUpdateCachedData,
}: TransactionFeeProps) {
  const [openPaymentDialog, setOpenPaymentDialog] = useState<boolean>(false);
  const [openTransFeesInfo, setOpenTransFeesInfo] = useState<boolean>(false);
  const [amountOfMoneyDisplay, setAmountOfMoneyDisplay] = useState<
    string | number
  >(0);
  const [transactionFeeDisplay, setTransactionFeeDisplay] = useState<
    string | number
  >(0);
  const amountOfMoneyDebounce = useDebounce(amountOfMoney, 1000);

  const currentUser = useMemo(() => {
    return JSON.parse(localStorage.getItem("currentUser") || "{}");
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setAmountOfMoneyDisplay(convertNumberWithDot(amountOfMoney));
      setTransactionFeeDisplay(transactionFees);
    }
  }, [isSuccess]);

  const getFeeAmountQuery = useQuery({
    queryKey: [amountOfMoneyDebounce],
    queryFn: () => {
      if (!isSuccess) return undefined;
      return SettingsService.getFeeAmount(
        { amountOfMoney: amountOfMoneyDebounce },
        currentUser.jwt
      );
    },
  });

  useEffect(() => {
    if (amountOfMoney === 0 || amountOfMoney === "")
      setTransactionFeeDisplay(0);
    else setTransactionFeeDisplay(getFeeAmountQuery.data);
  }, [getFeeAmountQuery.data]);

  return (
    <Container>
      <STextField
        label="Nhập tổng tiền"
        size="small"
        InputProps={{
          endAdornment: <InputAdornment position="end">VNĐ</InputAdornment>,
          sx: { borderRadius: 0 },
          // readOnly:
          //   contractState.isConfirmed ||
          //   !transactionAdmin.isTransactionAdmin,
        }}
        value={amountOfMoneyDisplay}
        onFocus={() => {
          setAmountOfMoneyDisplay(amountOfMoney);
          if (!amountOfMoney) {
            setAmountOfMoneyDisplay("");
            onUpdateCachedData("amountOfMoney", "");
          }
        }}
        onBlur={() => {
          setAmountOfMoneyDisplay(
            convertNumberWithDot(amountOfMoney == null ? 0 : amountOfMoney)
          );
          if (!amountOfMoney) {
            setAmountOfMoneyDisplay(0);
            onUpdateCachedData("amountOfMoney", 0);
          }
        }}
        onChange={(e) => {
          const regex = /^[0-9.\b]+$/;
          const value = e.target.value;
          if (value === "" || regex.test(value)) {
            setAmountOfMoneyDisplay(value);
            onUpdateCachedData("amountOfMoney", value);
          } else {
            toast.error("Tổng tiền phải là một số", {
              toastId: "amountOfMoney",
            });
          }
        }}
      />
      <STextField
        label="Nhập tỉ lệ cọc"
        size="small"
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
          sx: { borderRadius: 0 },
        }}
        value={depositRate == null ? 0 : depositRate}
        onFocus={() => {
          if (!depositRate) {
            onUpdateCachedData("depositRate", "");
          }
        }}
        onBlur={() => {
          if (!depositRate) {
            onUpdateCachedData("depositRate", 0);
          }
        }}
        onChange={(e) => {
          const regex = /^[0-9.\b]+$/;
          const value = e.target.value;
          if (value === "" || (regex.test(value) && Number(value) <= 100)) {
            onUpdateCachedData("depositRate", value);
          } else {
            toast.error("Tỉ lệ cọc phải là một số không lớn hơn 100", {
              toastId: "depositRate",
            });
          }
        }}
      />
      <STextField
        label="Phí giao dịch"
        size="small"
        value={
          transactionFeeDisplay == null
            ? "Đang tính..."
            : convertNumberToVND(transactionFeeDisplay)
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="Cách chúng tôi tính phí"
                arrow={true}
                onClick={() => setOpenTransFeesInfo(true)}
              >
                <InfoIcon
                  fontSize="small"
                  sx={{ cursor: "pointer", color: "#0095C5" }}
                />
              </Tooltip>
            </InputAdornment>
          ),
          sx: { borderRadius: 0 },
          readOnly: true,
        }}
      />
      <SButton
        size="small"
        color="info"
        sx={{ flex: "0 1 95px", borderRadius: 0 }}
        onClick={(e) => setOpenPaymentDialog(true)}
      >
        Thanh toán
      </SButton>
      <SFormDialog
        title="Thanh Toán Đến Ngân Hàng"
        cancelName="Đóng"
        isError={false}
        open={openPaymentDialog}
        close={() => setOpenPaymentDialog(false)}
        noSaveButton
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <Alert severity="info">
            Sau khi bạn chuyển tiền thành công chúng tôi sẽ chuyển trạng thái
            của đơn hàng thành "Đã Đặt Cọc". Lúc này người bán có thể chuyển
            hàng cho bạn.
          </Alert>
          <Typography>Tên ngân hàng: ACB</Typography>
          <Typography>Số tài khoản: 18021367</Typography>
          <Typography>Tên người nhận/Chủ thẻ: NGUYEN KHAC SI</Typography>
          <Typography>
            <span>Nội dung chuyển khoản: </span>
            <span style={{ fontWeight: "bold", color: "red" }}>
              {transferCode}
            </span>
          </Typography>
        </Box>
      </SFormDialog>
      <SFormDialog
        title="Cách Chúng Tôi Tính Phí"
        cancelName="Đóng"
        isError={false}
        open={openTransFeesInfo}
        close={() => setOpenTransFeesInfo(false)}
        noSaveButton
      >
        <Box
          sx={{
            div: {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            },
            ".small-photo-message": {
              width: "42%",
              objectFit: "contain",
            },
          }}
        >
          <ModalImage
            className="small-photo-message"
            small={transactionFeesInfo}
            large={transactionFeesInfo}
            hideDownload={true}
            showRotate={true}
          />
        </Box>
      </SFormDialog>
    </Container>
  );
}
