import MenuItem from "@mui/material/MenuItem";
import Select, { SelectProps } from "@mui/material/Select";

interface SSelectProps extends SelectProps {
  selectLabel?: string;
  options: Array<{ value: string; label: string }>;
  value: string;
  onChangeValue: (value: string) => void;
}

const SSelect = ({
  selectLabel,
  options,
  value,
  onChangeValue,
  ...props
}: SSelectProps) => {
  return (
    <Select
      value={value}
      label={selectLabel}
      onChange={(e) => onChangeValue(e.target.value as string)}
      {...props}
    >
      {options.map((option) => (
        <MenuItem key={option.label} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SSelect;
