import styled from "styled-components";
import PasswordTextField from "../PasswordTextField/PasswordTextField";
import STextField from "../TextField/STextField";
import { RegisterStepsProps } from "../../pages/RegisterPage";
import { isValidPassword, isValidPhoneNumber } from "../../untils/untils";

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export default function RequiredInput({
  newUser,
  setNewUser,
}: RegisterStepsProps) {
  return (
    <InputWrapper>
      <STextField
        required
        label="Mã OTP"
        value={newUser.otp}
        onChange={(e) => setNewUser({ ...newUser, otp: e.target.value })}
        isEmty={newUser.otp === ""}
      />
      <STextField
        required
        label="Họ và tên"
        value={newUser.name}
        onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
        isEmty={newUser.name === ""}
      />
      <STextField
        required
        label="Số điện thoại"
        value={newUser.phone}
        onChange={(e) => setNewUser({ ...newUser, phone: e.target.value })}
        isEmty={newUser.phone === ""}
        isValid={isValidPhoneNumber(newUser.phone)}
        messageHelper={
          isValidPhoneNumber(newUser.phone)
            ? ""
            : "Số điện thoại phải bắt đầu bắng +84 hoặc 84 hoặc 0. Theo sau là 9 đến 10 chữ số"
        }
      />
      <PasswordTextField
        required
        label="Mật khẩu"
        value={newUser.password}
        onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
        isEmty={newUser.password === ""}
        isValid={isValidPassword(newUser.password)}
      />
    </InputWrapper>
  );
}
