import * as React from "react";
import styled from "styled-components";
import TablePagination from "@mui/material/TablePagination";
import CreateTransaction from "./CreateTransaction";
import { useMemo, useState } from "react";
import {
  Transaction,
  TransactionStateVieEnum,
  TransactionStateEnum,
  MenuItemAction,
  BankAccountType,
  getTSEngToVie,
} from "../../types/type";
import UserService from "../../services/UserService";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "../../hooks/useDebounce";
import TransactionService from "../../services/TransactionService";
import SFormDialog from "../FormDialog/SFormDialog";
import { Box, Chip, InputAdornment, Typography } from "@mui/material";
import SSelect from "../Select/SSelect";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import SMenu from "../Menu/SMenu";
import STable from "../Table/STable";
import {
  INITIAL_PROFILE,
  INITIAL_TRANSACTION,
  TH_TABLE_HEAD,
} from "../../untils/constants";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../stores";
import {
  updatePagination,
  updateSearchQuery,
  updateSortOption,
} from "../../stores/pageCacheSlice";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import STextField from "../TextField/STextField";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import {
  alertStatus,
  convertNumberToVND,
  getTransactionStateColor,
} from "../../untils/untils";
import { toast } from "react-toastify";
import Moment from "react-moment";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100% - 74px);
  padding: 10px;
`;

export const Title = styled.div`
  flex: 0 1 24px;
  font-size: 20px;
  font-weight: 500;
  margin: 10px 0 0 0;
`;

export const ActionContainer = styled.div`
  flex: 0 1 40px;
  display: flex;
  justify-content: space-between;
  margin-bottom: -10px;
`;

export default function TransactionHistory() {
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState<{
    transactionStates: null | HTMLElement;
    bankAccounts: null | HTMLElement;
  }>({ transactionStates: null, bankAccounts: null });
  const [openDialog, setOpenDialog] = useState<{
    deleteTransaction: boolean;
    bankAccountInfo: boolean;
  }>({ deleteTransaction: false, bankAccountInfo: false });

  const [selectedTransaction, setSelectedTransaction] =
    useState<Transaction>(INITIAL_TRANSACTION);
  const [currentBankAccountInfo, setCurrentBankAccountInfo] = useState<{
    groupName: String;
    bankAccount: BankAccountType;
  }>({ groupName: "", bankAccount: INITIAL_PROFILE.bankAccount });

  const dispatch = useDispatch<AppDispatch>();
  const pagination = useSelector(
    (state: RootState) => state.pageCache.pagination
  );
  const searchQuery = useSelector(
    (state: RootState) => state.pageCache.searchQuery
  );
  const searchQueryDebounce = useDebounce(searchQuery, 1000);
  const sortOption = useSelector(
    (state: RootState) => state.pageCache.sortOption
  );

  const navigate = useNavigate();
  const currentUser = useMemo(() => {
    return JSON.parse(localStorage.getItem("currentUser") || "{}");
  }, []);

  //Pagination
  const handleChangePage = (event: unknown, newPage: number) => {
    dispatch(updatePagination({ ...pagination, currentPage: newPage }));
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      updatePagination({
        ...pagination,
        rowsPerPage: parseInt(event.target.value, 10),
      })
    );
  };

  //open or close Transaction States
  const handleMenu = (
    event: React.MouseEvent<HTMLElement>,
    type: "transactionStates" | "bankAccounts"
  ) => {
    if (type === "transactionStates") {
      setAnchorEl({ ...anchorEl, transactionStates: event.currentTarget });
      return;
    }
    setAnchorEl({ ...anchorEl, bankAccounts: event.currentTarget });
  };
  const handleClose = (type: "transactionStates" | "bankAccounts") => {
    if (type === "transactionStates") {
      setAnchorEl({ ...anchorEl, transactionStates: null });
      return;
    }
    setAnchorEl({ ...anchorEl, bankAccounts: null });
  };

  //get transaction list with pagination
  const { data: transactionList } = useQuery({
    queryKey: [
      "transactionList",
      { ...pagination, sortOption, searchQueryDebounce },
    ],
    queryFn: () =>
      UserService.getTransactionList(
        {
          current: pagination.currentPage + 1,
          limit: pagination.rowsPerPage,
          sortField: sortOption === "oldest" ? "" : "-createdAt",
          keyword: searchQueryDebounce,
        },
        currentUser.jwt
      ),
  });

  //delete
  const handleDeteteTransaction = async (transactionId: string) => {
    try {
      await TransactionService.deleteTransaction(
        transactionId,
        currentUser.jwt
      ).then((res) => {
        toast.success("Giao dịch đã được xoá thành công");
        queryClient.invalidateQueries({
          queryKey: ["transactionList"],
        });
      });
    } catch (error: any) {
      alertStatus(error.response.data.message);
    } finally {
    }
  };

  const handleShowBankAccount = (groupName: "bên mua" | "bên bán") => {
    setOpenDialog({ ...openDialog, bankAccountInfo: true });
    setCurrentBankAccountInfo({
      groupName,
      bankAccount:
        (groupName === "bên mua"
          ? selectedTransaction.adminA?.bankAccount
          : selectedTransaction.adminB?.bankAccount) ||
        INITIAL_PROFILE.bankAccount,
    });
  };

  const bankAccounts: Array<MenuItemAction> = [
    {
      actionName: "Bên mua",
      onClickMenuItem: () => handleShowBankAccount("bên mua"),
    },
    {
      actionName: "Bên bán",
      onClickMenuItem: () => handleShowBankAccount("bên bán"),
    },
  ];

  //update TransactionState
  function handleUpdateTransactionState(
    transactionState?: TransactionStateEnum
  ) {
    TransactionService.updateTransactionState(
      selectedTransaction._id,
      transactionState || TransactionStateEnum.Created,
      currentUser.jwt
    )
      .then((res) => {
        queryClient.invalidateQueries({
          queryKey: ["transactionList"],
        });
      })
      .catch((error) => {
        alertStatus(error.response.data.message);
      });
  }
  const transactionStates: Array<MenuItemAction> = [
    {
      actionName: TransactionStateVieEnum.Deposited,
      onClickMenuItem: handleUpdateTransactionState,
    },
    {
      actionName: TransactionStateVieEnum.Sent,
      onClickMenuItem: handleUpdateTransactionState,
    },
    {
      actionName: TransactionStateVieEnum.Received,
      onClickMenuItem: handleUpdateTransactionState,
    },
    {
      actionName: TransactionStateVieEnum.Accomplished,
      onClickMenuItem: handleUpdateTransactionState,
    },
  ];

  //handle click
  const handleClickDetail = (transaction: Transaction) => {
    navigate(`/agreement/${transaction._id}`, {
      state: { id: transaction._id },
    });
  };
  const handleClickBankAccounts = (
    transaction: Transaction,
    event?: React.MouseEvent<HTMLElement>
  ) => {
    setSelectedTransaction(transaction);
    handleMenu(event as React.MouseEvent<HTMLElement>, "bankAccounts");
  };
  const handleClickDelete = (transaction: Transaction) => {
    setSelectedTransaction(transaction);
    setOpenDialog({ ...openDialog, deleteTransaction: true });
  };
  const handleClickChangeTS = (
    transaction: Transaction,
    event?: React.MouseEvent<HTMLElement>
  ) => {
    setSelectedTransaction(transaction);
    handleMenu(event as React.MouseEvent<HTMLElement>, "transactionStates");
  };

  const ACTION_CELL_DATA = [
    {
      actionName: "Chi tiết",
      icon: <ListAltOutlinedIcon fontSize="small" />,
      buttonColor: "secondary",
      onClick: handleClickDetail,
      roles: { isAdmin: true, isUser: true },
    },
    {
      actionName: "Tài khoản ngân hàng",
      icon: <AccountBalanceOutlinedIcon fontSize="small" />,
      buttonColor: "warning",
      onClick: handleClickBankAccounts,
      roles: { isAdmin: true },
    },
    {
      actionName: "Xoá",
      icon: <DeleteOutlineIcon fontSize="small" />,
      buttonColor: "error",
      onClick: handleClickDelete,
      roles: { isUser: true },
    },
    {
      actionName: "Cập nhật trạng thái giao dịch",
      icon: <AutorenewOutlinedIcon fontSize="small" />,
      buttonColor: "info",
      onClick: handleClickChangeTS,
      roles: { isAdmin: true },
    },
  ];

  const tableBodyData = useMemo(() => {
    return (transactionList?.data || []).map((trans) => {
      return {
        ...trans,
        createdAt: <Moment format="YYYY-MM-DD HH:mm">{trans.createdAt}</Moment>,
        amountOfMoney: convertNumberToVND(trans.amountOfMoney),
        transactionState: (
          <Chip
            label={getTSEngToVie(trans.transactionState)}
            size="small"
            sx={{
              color: "#ffffff",
              minWidth: 109,
              backgroundColor: getTransactionStateColor(trans.transactionState),
            }}
          />
        ),
      };
    });
  }, [transactionList?.data]);

  return (
    <Container>
      <Title>Lịch Sử Giao Dịch</Title>
      <ActionContainer>
        <STextField
          variant="outlined"
          size="small"
          sx={{ width: "40%" }}
          placeholder={`Tìm kiếm ${
            currentUser.isAdmin
              ? "theo tên giao dịch hoặc mã chuyển khoản"
              : "theo tên giao dịch"
          }`}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
            sx: { borderRadius: 20 },
          }}
          value={searchQuery}
          onChange={(e) => dispatch(updateSearchQuery(e.target.value))}
        />
        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
          <SSelect
            size="small"
            options={[
              { value: "oldest", label: "Cũ nhất" },
              { value: "-createdAt", label: "Mới nhất" },
            ]}
            value={sortOption}
            onChangeValue={(value: string) => {
              dispatch(updateSortOption(value));
            }}
          />
          <CreateTransaction />
        </div>
      </ActionContainer>

      {/* Table */}
      <STable
        tableHeadData={TH_TABLE_HEAD}
        tableBodyData={tableBodyData}
        actionCellData={ACTION_CELL_DATA}
      />
      {/* Dialog */}
      <SMenu
        anchorEl={anchorEl.bankAccounts}
        onClose={() => handleClose("bankAccounts")}
        menuItemActions={bankAccounts}
      />
      <SMenu
        anchorEl={anchorEl.transactionStates}
        onClose={() => handleClose("transactionStates")}
        menuItemActions={transactionStates}
      />
      <SFormDialog
        title={selectedTransaction.name.toUpperCase()}
        cancelName="Đã hiểu"
        isError={false}
        open={openDialog.bankAccountInfo}
        close={() => setOpenDialog({ ...openDialog, bankAccountInfo: false })}
        noSaveButton
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <Typography fontWeight={600}>
            Thông tin tài khoản ngân hàng {currentBankAccountInfo.groupName}
          </Typography>
          <Typography>
            Tên ngân hàng: {currentBankAccountInfo.bankAccount.bankName}
          </Typography>
          <Typography>
            Số tài khoản: {currentBankAccountInfo.bankAccount.accountNumber}
          </Typography>
          <Typography>
            Tên người nhận/Chủ thẻ:{" "}
            {currentBankAccountInfo.bankAccount.accountOwner}
          </Typography>
        </Box>
      </SFormDialog>
      <SFormDialog
        title={`Bạn Có Chắc Chắn Muốn Xóa Giao Dịch Này?`}
        saveName="Xoá"
        isError={false}
        open={openDialog.deleteTransaction}
        close={() => setOpenDialog({ ...openDialog, deleteTransaction: false })}
        onSave={() => handleDeteteTransaction(selectedTransaction._id || "")}
      >
        <>Tên giao dịch: {selectedTransaction.name}</>
      </SFormDialog>
      {/* Pagination */}
      <TablePagination
        sx={{ flex: "0 1 52px", mt: "-20px" }}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={Number(transactionList?.total) || 0}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.currentPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  );
}
