import styled from "styled-components";
import Moment from "react-moment";
import { Message as MessageType } from "../../../types/type";

const Container = styled.div<{
  $isLeft: boolean;
  $isFirstPoint: boolean;
}>`
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  height: fit-content;
  background-color: ${(props) => (props.$isLeft ? "#ffffff" : "#A9E1F7")};
  border-radius: 6px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.$isLeft ? "flex-start" : "flex-end")};
  width: fit-content;
  min-width: 60px;
  max-width: 80%;
  margin-bottom: 2px;
  margin-left: ${(props) =>
    props.$isLeft && !props.$isFirstPoint ? "45px" : "0px"};
  margin-right: ${(props) =>
    !props.$isLeft && !props.$isFirstPoint ? "45px" : "0px"};
  .sending-time {
    font-size: 10px;
    color: grey;
  }
`;
const Sender = styled.div<{ $isFirstPoint: boolean }>`
  color: gray;
  font-size: 12px;
  display: ${(props) => (props.$isFirstPoint ? "flex" : "none")};
`;
const TextMessage = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const calendarStrings = {
  lastDay: "HH:mm [Yesterday]",
  sameDay: "HH:mm",
  nextDay: "[Tomorrow at] HH:mm",
  lastWeek: "HH:mm [last] dddd",
  nextWeek: "dddd [at] HH:mm",
  sameElse: "HH:mm L",
};

interface MessageProps {
  message: MessageType;
  isLeft: boolean;
  isFirstPoint: boolean;
}

const Message = ({ message, isLeft, isFirstPoint }: MessageProps) => {
  return (
    <Container $isLeft={isLeft} $isFirstPoint={isFirstPoint}>
      <Sender $isFirstPoint={isFirstPoint}>{message.senderName}</Sender>
      <TextMessage>{message.message}</TextMessage>
      <Moment className="sending-time" calendar={calendarStrings}>
        {message.createdAt}
      </Moment>
    </Container>
  );
};

export default Message;
